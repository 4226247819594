/**
 * @copyright 2023 @ DigiNet
 * @author TRANGHOANG
 * @create 10/1/2023
 * @Example
 */

import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { browserHistory } from "react-router";
import ListApproval from "../../../common/list-approval";

import * as W51F2200Actions from "../../../../redux/W5X/W51F2200/W51F2200_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import Config from "../../../../config";
import { Checkbox, DateRangePicker, Dropdown, Col } from "diginet-core-ui/components";

const FormID = "W51F2200";
const W51F2200 = props => {
    const dispatch = useDispatch();
    const [listStatus, setListStatus] = useState([]);
    const [isShowOther, setIsShowOther] = useState(0);
    const [dataCboEmployees, setDataCboEmployees] = useState({});
    const [loadingCboEmployees, setLoadingCboEmployees] = useState(false);

    const gridLeft = useRef(null);

    const paramFilterCboEmployees = useRef({ skip: 0, limit: 50 });

    useEffect(() => {
        gridLeft.current.loadGrid();
    }, [isShowOther]);

    const loadCboEmployees = isReset => {
        const param = {
            Type: "EmployeeID",
            FormID: FormID,
            Language: Config.language || "84",
            ...paramFilterCboEmployees.current,
        };
        setLoadingCboEmployees(true);
        dispatch(
            generalActions.getCboEmployees(param, (err, data) => {
                setLoadingCboEmployees(false);
                if (err) return Config.popup.show("ERROR", err);
                if (data) {
                    const rows = data?.rows ?? data;
                    const total = data?.total ?? data?.length;
                    setDataCboEmployees({
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total,
                    });
                }
            })
        );
    };

    const renderHeader = () => {
        return (
            <Checkbox
                className={"mgv3x"}
                label={Config.lang("Hien_don_tu_nguoi_khac_tao")}
                checked={isShowOther}
                onChange={e => setIsShowOther(+e.target.checked)}
            />
        );
    };

    const renderFilter = (dataFilter, filterChange) => {
        return (
            <>
                <Col xs={12}>
                    <DateRangePicker
                        clearAble
                        controls
                        defaultValue={[dataFilter.DateFrom ?? null, dataFilter.DateTo ?? null]}
                        label={Config.lang("Ngay")}
                        onChange={e => filterChange("Date", e, "Date")}
                        placeholder={`${Config.lang("Tu_ngay")} - ${Config.lang("Den_ngay")}`}
                        returnFormat={"YYYY-MM-DD"}
                        viewType={"outlined"}
                    />
                </Col>
                <Col xs={12}>
                    <Dropdown
                        clearAble
                        valueExpr={"AppStatusID"}
                        displayExpr={"AppStatusName"}
                        placeholder={Config.lang("Chon")}
                        label={Config.lang("Trang_thai_duyet")}
                        onChange={e => filterChange("AppStatusID", e)}
                        dataSource={listStatus}
                        defaultValue={dataFilter.AppStatusID ?? null}
                        viewType={"outlined"}
                    />
                </Col>
                <Col xs={12}>
                    <Dropdown
                        allowSearch
                        clearAble
                        dataSource={dataCboEmployees.rows}
                        total={dataCboEmployees.total}
                        skip={paramFilterCboEmployees.current.skip}
                        limit={paramFilterCboEmployees.current.limit}
                        displayExpr={"{EmployeeID} - {EmployeeName}"}
                        keyExpr={"EmployeeName"}
                        valueExpr={"EmployeeID"}
                        placeholder={Config.lang("Chon")}
                        label={Config.lang("Nhan_vien")}
                        onChange={e => filterChange("EmployeeID", e)}
                        defaultValue={dataFilter.EmployeeID ?? null}
                        viewType={"outlined"}
                        loading={loadingCboEmployees}
                        onInput={e => {
                            paramFilterCboEmployees.current.search = e?.target?.value ?? "";
                            paramFilterCboEmployees.current.skip = 0;
                            loadCboEmployees(true);
                        }}
                        onLoadMore={e => {
                            paramFilterCboEmployees.current.skip = e?.skip ?? 0;
                            paramFilterCboEmployees.current.limit = e?.limit ?? 10;
                            loadCboEmployees();
                        }}
                    />
                </Col>
            </>
        );
    };

    const onLoadCboFilter = () => {
        loadCboEmployees(true);
    };

    const _onAdd = () => {
        browserHistory.push({
            pathname: Config.getRootPath() + "W51F2201",
            state: { mode: "add", FormParent: FormID },
        });
    };

    const _onView = e => {
        const { data } = e.row;
        browserHistory.push({
            pathname: Config.getRootPath() + "W51F2201",
            state: { mode: "edit", TransGroupID: data.TransGroupID, AppStatusID: data.AppStatusID },
        });
    };

    return (
        <>
            <ListApproval
                title={Config.lang("de_xuat_cap_phat_trang_thiet_bi_lao_dong")}
                ref={gridLeft}
                FormID={FormID}
                typePaging="remote"
                keyExpr={"TransGroupID"}
                height={Config.getHeightGrid() - 40}
                renderHeader={renderHeader}
                onLoadCboFilter={onLoadCboFilter}
                renderFilter={renderFilter}
                onAdd={_onAdd}
                action={{
                    loadGrid: (params, cb) =>
                        W51F2200Actions.loadGrid(
                            { ...params, IsShowOther: isShowOther, AppStatusID: params.AppStatusID?.toString() || "" },
                            cb
                        ),
                    loadStatus: (
                        params,
                        cb // action load data Dropdown Status (use to column renderStatus and DD Filter)
                    ) =>
                        generalActions.getCboAppStatus({ ...params, FormID: "STANDARD" }, (err, data) => {
                            setListStatus(data);
                            cb && cb(err, data);
                        }),
                }}
                renderAction={() => {
                    return {
                        History: true, //icon lịch sử
                        View: {
                            action: _onView, //icon con mắt
                        },
                        Delete: {
                            action: W51F2200Actions.deleteRow,
                        },
                    };
                }}
                renderInfo={{
                    [Config.lang("Kho")]: "WareHouseName",
                    [Config.lang("Ly_do")]: "ReasonName",
                    [Config.lang("So_luong_nhan_vien")]: "QuantityEmp",
                }}
            />
        </>
    );
};

export default W51F2200;
