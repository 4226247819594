
/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 07/05/2021
 * @Example
 */
import { Divider } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Column } from "devextreme-react/data-grid";
import { Button, Dropdown, DateRangePicker, ButtonIcon } from 'diginet-core-ui/components';
import { CalendarNew } from 'diginet-core-ui/icons';
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W51F1100Actions from "../../../../redux/W51/W51F1100/W51F1100_actions";
import Api from "../../../../services/api";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import UserImage from "../../../common/user/user-image";
import UserName from "../../../common/user/user-name";
import Filter from "../../../filter/filter";
import GridContainer from "../../../grid-container/grid-container";
import History from "../../../libs/history";

const styles = {
    dateInfo: {
        display: "flex",
        alignItems: "center",
    },
    dateRangeClass: {
        '& input': {
            padding: '10px !important'
        }
    }
};

const W51F1100 = (props) => {
    const { dataGrid, getCboWarehouse, classes } = props;
    const FormID = "W51F1100";
    const Language = Config.language || "84";
    const formatTimes = "DD/MM/YYYY";
    const DateFrom = moment().startOf('year').format("YYYY-MM-DD");
    const DateTo = moment().endOf('year').format("YYYY-MM-DD");


    const [gridLoading, setGridLoading] = useState(false);
    const [cboWareHouseLoading, setCboWareHouseLoading] = useState(false);
    const [cboEmployeeLoading, setCboEmployeeLoading] = useState(false);
    const [iPermission, setIPermission] = useState(0);
    const [filterSearch, setFilterSearch] = useState({
        Employee: "",
        EmployeeID: "",
        WareHouseID: ""
    });
    const [dataCboEmployees, setDataCboEmployees] = useState({
        rows: [],
        total: 0
    });
    const [rangeDate, setRangeDate] = useState([DateFrom, DateTo]);


    const itemPageDataGrid = useRef({
        limit: 10,
        skip: 0
    });
    const filterCboEmployees = useRef({
        Type: "EmployeeID",
        timer: null,
        HostID: "",
        strSearch: "",
        skip: 0,
        limit: 50
    });
    const filterRangePicker = useRef({
        DateFrom,
        DateTo,
    });

    useEffect(() => {
        loadPermission();
        if (!iPermission) return;
        loadGrid();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iPermission]);

    const loadGrid = () => {
        const { WareHouseID, EmployeeID } = filterSearch;
        const { DateFrom, DateTo } = filterRangePicker.current;
        const { skip: Skip, limit: Limit } = itemPageDataGrid.current;
        const params = {
            FormID,
            DateFrom,
            DateTo,
            EmployeeID,
            WareHouseID,
            Language,
            Skip,
            Limit,
        };
        setGridLoading(true);
        props.w51F1100Actions.loadGrid(params, (error, data) => {
            setGridLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    const loadCboEmployees = (isReset) => {
        const { HostID, Type, skip, limit, strSearch: search } = filterCboEmployees.current;
        const param = {
            HostID,
            Type,
            FormID,
            Language,
            skip,
            limit,
            search
        };
        setCboEmployeeLoading(true);
        props.generalActions.getCboEmployees(param, (error, data) => {
            setCboEmployeeLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            else if (data) {
                const rows = data?.rows ? data.rows : data;
                const total = data?.total ? data.total : data.length;
                setDataCboEmployees({
                    rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                    total
                })
            }
        });
    };

    const loadCboWarehouse = () => {
        const params = {
            FormID,
            Language,
        };
        setCboWareHouseLoading(true);
        props.generalActions.getCboWarehouse(params, (err) => {
            setCboWareHouseLoading(false);
            if (err) {
                Config.popup.show("ERROR", err);
                return;
            }
        });
    };

    const loadPermission = useCallback(() => {
        props.generalActions.getPermission("W51F1100", (isPer) => {
            setIPermission(isPer);
        });
    }, [props.generalActions]);

    const redirectPage = (mode, e) => {
        if (!mode) return;
        browserHistory.push({
            pathname: Config.getRootPath() + "W51F1101",
            state: {
                mode,
                iPermission,
                TransGroupID: _.get(e?.data, "TransGroupID", "")
            }
        });
    }

    const onDelete = async (e) => {
        const { data } = e;
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_chan_muon_xoa_du_lieu_nay"), () => {
            (async () => {
                const params = {
                    FormID,
                    Language,
                    TransGroupID: _.get(data, "TransGroupID", ""),
                };
                setGridLoading(true);
                const res = await Api.put("/w51f1000/delete", params);
                const status = _.get(res, "data.Status", 1);
                setGridLoading(false);
                if (status) {
                    const message = _.get(res, "data.Message", Config.lang("Ton_tai_nhan_vien_da_duoc_thu_hoi,_ban_khong_duoc_phep_xoa"));
                    Config.popup.show("INFO", message);
                } else {
                    // Bằng 0
                    const statusSaveHistory = await onSaveHistory(data);
                    if (statusSaveHistory) {
                        Config.notify.show("success", Config.lang("Xoa_thanh_cong"), 2000);
                        loadGrid();
                    } else {
                        Config.popup.show("ERROR", Config.lang("Loi_chua_xac_dinh"));
                    }
                }
            })();
        });
    };

    const onSaveHistory = async (data = {}) => {
        const { TransGroupID = "" } = data;
        let status = false;
        const captions = {
            CreateUserID: "Ma_nhan_vien",
            UserName: "Ten_nhan_vien",
            CreateDate: "Ngay_tao",
            ReTrieveDate: "Ngay_thu_hoi",
            Reason: "Ly_do",
            QuantityEmp: "Tong_so_NV",
            WareHouseName: "Kho"
        };
        const options = {
            data,
            captions,
            action: 3, //Delete
            ModuleID: "D51",
            TransactionID: FormID,
            TransID: TransGroupID,
            TransactionName: Config.lang("Thu_hoi_trang_thiet_bi_lao_dong"),
            itemRender: (e, type) => {
                const { key, data } = e;
                if (type === "Content") {
                    if (key === "CreateDate" || key === "ReTrieveDate") {
                        return !_.isNull(data[key]) ? moment.utc(data[key]).format(formatTimes) : data[key];
                    }
                    if (key === "UserName") {
                        const { UserNameU } = Config.profile || {};
                        return UserNameU;
                    }
                }
            }
        };
        const history = new History(options);
        if (history.get()?.length > 0) {
            if (await history.save()) status = true;
        }
        return status;
    }

    const filterChange = (filterName = "", e) => {
        if (!e) return;
        let value = _.get(e, "value", _.get(e, "target.value", ""));
        switch (filterName) {
            case "WareHouseID":
            case "EmployeeID":
                setFilterSearch({ ...filterSearch, [filterName]: value });
                break;
            case "RangePicker":
                const DateFrom = _.isArray(value) ? value[0] : value;
                const DateTo = _.isArray(value) ? value[1] : value;
                filterRangePicker.current = {
                    DateFrom,
                    DateTo,
                }
                setRangeDate([DateFrom, DateTo]);
                break;
            default:
                break;
        }
    }

    const onLoadDataFilter = () => {
        loadCboWarehouse();
        loadCboEmployees();
    }

    const renderFilterData = () => {
        return (
            <Filter
                isUseDDCore={true}
                openOnFieldClick={true}
                placeholder={Config.lang("Noi_dung_can_tim_kiem")}
                onOpenLoaded={onLoadDataFilter}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <span className={classes.dateRangeClass}>
                                            <DateRangePicker
                                                clearAble
                                                controls
                                                value={rangeDate}
                                                style={{ marginBottom: 0 }}
                                                viewType={"outlined"}
                                                label={Config.lang("Ngay_thu_hoi")}
                                                onChange={e => filterChange("RangePicker", e)}
                                                placeholder={"DD/MM/YY - DD/MM/YY"}
                                                returnFormat={"YYYY-MM-DD"}
                                            />
                                        </span>
                                    </Col>
                                    <Col style={{ marginTop: 10 }} xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            clearAble
                                            style={{ margin: 0 }}
                                            dataSource={_.get(dataCboEmployees, "rows", [])}
                                            total={_.get(dataCboEmployees, "total", 0)}
                                            skip={filterCboEmployees.current.skip}
                                            limit={filterCboEmployees.current.limit}
                                            value={filterSearch.EmployeeID}
                                            loading={cboEmployeeLoading}
                                            viewType={"outlined"}
                                            valueExpr={"EmployeeID"}
                                            renderSelectedItem={"EmployeeName"}
                                            searchDelayTime={1000}
                                            // iconExpr={"UserPictureURL"}
                                            label={Config.lang("Nhan_vien")}
                                            placeholder={Config.lang("Chon")}
                                            displayExpr={"{EmployeeID} - {EmployeeName}"}
                                            onChange={(e) => filterChange("EmployeeID", e)}
                                            onInput={(e) => {
                                                filterCboEmployees.current.strSearch = e.target.value;
                                                filterCboEmployees.current.skip = 0;
                                                loadCboEmployees(true);
                                            }}
                                            onLoadMore={(e) => {
                                                filterCboEmployees.current.skip = e.skip;
                                                filterCboEmployees.current.limit = e.limit;
                                                loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                    <Col style={{ marginTop: 10 }} xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            clearAble
                                            style={{ margin: 0 }}
                                            viewType={"outlined"}
                                            valueExpr={"WareHouseID"}
                                            displayExpr={"WareHouseName"}
                                            placeholder={Config.lang("Chon")}
                                            label={Config.lang("Kho_thu_hoi")}
                                            onChange={e => filterChange("WareHouseID", e)}
                                            dataSource={getCboWarehouse}
                                            loading={cboWareHouseLoading}
                                            value={filterSearch.WareHouseID}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <div style={{ display: "flex", justifyContent: "center", }}>
                                <Button
                                    size={"medium"}
                                    color={"primary"}
                                    viewType={"outlined"}
                                    label={Config.lang("Tim_kiem")}
                                    startIcon={"Search"}
                                    onClick={loadGrid}
                                />
                            </div>
                        </>
                    );
                }}
            />
        );
    };

    const renderEmpProfile = (e) => {
        const { data } = e.row;
        const { CreateDate = "", DepartmentName = "" } = data;
        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <UserImage data={data} />
                <div className={"w75f2000_info"}>
                    <div className={classes.divText}>
                        <UserName data={data} />
                    </div>
                    <div className={classes.divText}>
                        {DepartmentName}
                    </div>
                    <div className={classes.dateInfo}>
                        <span disabled={true}>
                            <CalendarNew
                                height={14}
                                width={14} />
                        </span>
                        <div
                            style={{ paddingLeft: "8px" }}
                            className={"date-text-info"}>
                            {CreateDate && moment.utc(CreateDate).format("LT, DD/MM/YYYY")}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderInfo = (e) => {
        const { RetrieveDate = "", Reason = "", QuantityEmp = "", WareHouseName = "" } = e.row.data;
        const retrieveDate = moment(RetrieveDate).format("DD/MM/YYYY");
        return (
            <div className={"pdt10 pdb10 " + classes.divText}>
                <div> {`${Config.lang("Ngay_thu_hoi")}: ${retrieveDate}`} </div>
                <div> {`${Config.lang("Ly_do")}: ${Reason}`} </div>
                <div> {`${Config.lang("Tong_so_NV")}: ${QuantityEmp}`} </div>
                {!_.isEmpty(WareHouseName) && <div> {`${Config.lang("Kho")}: ${WareHouseName}`} </div>}
            </div>
        );
    };

    const onChangeInfoPage = (page) => {
        itemPageDataGrid.current.skip = page * itemPageDataGrid.current.limit;
        loadGrid();
    }

    const onChangePerPage = (perPage) => {
        itemPageDataGrid.current.skip = 0;
        itemPageDataGrid.current.limit = perPage;
        loadGrid();
    }

    const renderAction = (e) => {
        return (
            <div className={"display_row align-center"}>
                <ButtonIcon
                    circular
                    viewType={"text"}
                    name={"View"}
                    disabled={!(iPermission > 2)}
                    onClick={() => redirectPage("view", e)}
                />
                <Divider
                    orientation={"vertical"}
                    style={{ width: 2, height: 11, margin: "0 5px" }}
                />
                <ButtonIcon
                    circular
                    viewType={"text"}
                    name={"Delete"}
                    disabled={!(iPermission > 3)}
                    onClick={() => onDelete(e)}
                />
            </div>
        );
    };

    if (!iPermission) return null;
    return (
        <React.Fragment>
            <div className={"hidden"}>{renderFilterData()}</div>
            <ActionToolbar title={Config.lang("Thu_hoi_trang_thiet_bi_lao_dong")}>
                <Button
                    disabled={!(iPermission > 1)}
                    size={"medium"}
                    color={"primary"}
                    viewType={"filled"}
                    startIcon={"Input"}
                    label={Config.lang("Thu_hoi")}
                    onClick={() => redirectPage("add")}
                />
            </ActionToolbar>
            <GridContainer
                itemPerPage={itemPageDataGrid.current.limit}
                skipPerPage={itemPageDataGrid.current.skip}
                showRowLines={true}
                showBorders={false}
                showColumnLines={false}
                showColumnHeaders={false}
                hoverStateEnabled={true}
                typePaging={"remote"}
                noDataText={Config.lang("No_data")}
                height={Config.getHeightGrid()}
                style={{ border: 'none' }}
                loading={gridLoading}
                totalItems={_.get(dataGrid, "total", 0)}
                dataSource={_.get(dataGrid, "rows", [])}
                keyExpr={"TransGroupID"}
                editing={{ texts: { confirmDeleteMessage: "" } }}
                onDbCellClick={(e) => redirectPage("view", e)}
                onChangePage={onChangeInfoPage}
                onChangePerPage={onChangePerPage}
            >
                <Column cellRender={renderEmpProfile} width={250} />
                <Column cellRender={renderInfo} />
                <Column cellRender={renderAction} width={200} />
            </GridContainer>
        </React.Fragment>
    );
};

export default compose(
    connect((state) => ({
        dataGrid: state.W51F1100.dataGrid,
        getCboWarehouse: state.general.getCboWarehouse,
    }), (dispatch) => ({
        generalActions: bindActionCreators(generalActions, dispatch),
        w51F1100Actions: bindActionCreators(W51F1100Actions, dispatch),
    })), withStyles(styles))(W51F1100);
