/**
 * @copyright 2023 @ DigiNet
 * @author THANHAN
 * @create 10/01/2023
 * @Example
 */

import React, { useEffect, useState, useRef } from 'react';
import {
    Row,
    Col,
    DateRangePicker,
    Dropdown,
    Button,
} from "diginet-core-ui/components";
import Filter from "../../../filter/filter";
import Approvals from "../../../approvals/approvals";
import ActionToolbar from '../../../common/toolbar/action-toolbar';
import W51F2202FormInfo from './W51F2202FormInfo';
import Config from '../../../../config';
import { useDispatch } from 'react-redux';
import * as generalActions from "../../../../redux/general/general_actions";
import * as approvalActions from "../../../../redux/approvals/approvals_actions";

export default function W51F2202(props) {
    const dispatch = useDispatch();
    const FormID = "W51F2202";
    
    const [iPermission, setIPermission] = useState(0);
    const [listStatus, setListStatus] = useState([]);
    const [dataCboEmployees, setDataCboEmployees] = useState({});
    const [loadingCboEmployees, setLoadingCboEmployees] = useState(false);
    const [loading, setLoading] = useState(false);
    const [voucherID, setVoucherID] = useState("");
    const [dataForm, setDataForm] = useState({});
    const [dataFilter, setDataFilter] = useState({
        DateFrom: null,
        DateTo: null,
        EmployeeID: "",
        ApprovalStatus: null,
    });
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [employee, setEmployee] = useState(null);
    const [appStatusID, setAppStatusID] = useState(null);

    const paramFilterCboEmployees = useRef({ skip: 0, limit: 50 });

    const loadCboEmployees = isReset => {
        const param = {
            Type: "EmployeeID",
            FormID,
            Language: Config.language || "84",
            ...paramFilterCboEmployees.current,
        };
        setLoadingCboEmployees(true);
        dispatch(
            generalActions.getCboEmployees(param, (err, data) => {
                setLoadingCboEmployees(false);
                if (err) return Config.popup.show("ERROR", err);
                if (data) {
                    const rows = data?.rows ?? data;
                    const total = data?.total ?? data?.length;
                    setDataCboEmployees({
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total,
                    });
                }
            })
        );
    };

    const loadCboStatus = () => {
        const params = {
            FormID: "STANDARD",
            Language: Config.language || '84'
        };
        setLoading(true);
        dispatch(generalActions.getCboAppStatus(params, (error, data) => {
            setLoading(false);
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            else if (data) {
                setListStatus(data);
            }
        }));
    };

    const loadFormInfo = (VoucherID) => {
        const params = {
            DivisionID: Config.getDivisionID(),
            FormID,
            Language: Config.language || "84",
            VoucherID: VoucherID ? VoucherID : "",
        };
        setLoading(true);
        dispatch(approvalActions.getFormInfo(params, (error, data) => {
            setLoading(false);
            if (error) return Config.popup.show("ERROR", error);
            if (data) {
                setDataForm(data);
            };
        }));
    };

    const onLoadCboFilter = () => {
        loadCboEmployees(true);
        loadCboStatus();
    };

    const onDrawerClosed = () => {
        setVoucherID("");
    };

    const loadPermission = () => {
        dispatch(generalActions.getPermission(FormID, (isPer) => {
            if (isPer) {
                setIPermission(isPer);
            }
        }))
    }

    const renderFormInfo = (data) => {
        return (
            <W51F2202FormInfo
                data={data}
            />
        );
    };

    const filterChange = (key, e) => {
        const value = e?.value ?? e?.data?.value ?? e?.target?.value ?? e;
        switch (key) {
            case "Date":
                setDateFrom(value?.[0] ?? null);
                setDateTo(value?.[1] ?? null);
                break;
            case "EmployeeID":
                setEmployee(value ?? "");
                break;
            case "AppStatusID":
                setAppStatusID(value ?? null);
                break;
            default:
                break;
        };
    };

    const renderFilter = () => {
        return (
            <Filter
                isUseDDCore={true}
                placeholder={Config.lang("Noi_dung_can_tim_kiem")}
                showHeader={false}
                openOnFieldClick={true}
                renderFilter={() => {
                    return (
                        <Row>
                            <Col xs={12}>
                                <DateRangePicker
                                    clearAble
                                    controls
                                    defaultValue={[dataFilter.DateFrom ?? null, dataFilter.DateTo ?? null]}
                                    label={Config.lang("Ngay")}
                                    onChange={e => filterChange("Date", e)}
                                    placeholder={`${Config.lang("Tu_ngay")} - ${Config.lang("Den_ngay")}`}
                                    returnFormat={"YYYY-MM-DD"}
                                    viewType={"outlined"}
                                />
                            </Col>
                            <Col xs={12}>
                                <Dropdown
                                    clearAble
                                    valueExpr={"AppStatusID"}
                                    displayExpr={"AppStatusName"}
                                    placeholder={Config.lang("Chon")}
                                    label={Config.lang("Trang_thai_duyet")}
                                    onChange={e => filterChange("AppStatusID", e)}
                                    dataSource={listStatus}
                                    defaultValue={dataFilter.AppStatusID ?? null}
                                    viewType={"outlined"}
                                    loading={loading}
                                />
                            </Col>
                            <Col xs={12}>
                                <Dropdown
                                    allowSearch
                                    searchDelayTime
                                    clearAble
                                    dataSource={dataCboEmployees.rows}
                                    total={dataCboEmployees.total}
                                    skip={paramFilterCboEmployees.current.skip}
                                    limit={paramFilterCboEmployees.current.limit}
                                    displayExpr={"{EmployeeID} - {EmployeeName}"}
                                    keyExpr={"EmployeeName"}
                                    valueExpr={"EmployeeID"}
                                    placeholder={Config.lang("Chon")}
                                    label={Config.lang("Nhan_vien")}
                                    onChange={e => filterChange("EmployeeID", e)}
                                    defaultValue={dataFilter.EmployeeID ?? null}
                                    viewType={"outlined"}
                                    loading={loadingCboEmployees}
                                    onInput={e => {
                                        paramFilterCboEmployees.current.search = e?.target?.value ?? "";
                                        paramFilterCboEmployees.current.skip = 0;
                                        loadCboEmployees(true);
                                    }}
                                    onLoadMore={e => {
                                        paramFilterCboEmployees.current.skip = e?.skip ?? 0;
                                        paramFilterCboEmployees.current.limit = e?.limit ?? 10;
                                        loadCboEmployees();
                                    }}
                                />
                            </Col>
                            <Col xs={12} className="display_row valign-middle">
                                <Button
                                    color={"primary"}
                                    viewType={'outlined'}
                                    startIcon={'Search'}
                                    text={Config.lang('Tim_kiem')}
                                    onClick={onSearch}
                                />
                            </Col>
                        </Row>
                    )
                }}
            />

        );
    };

    const onSearch = () => {
        setDataFilter({
            ...dataFilter,
            ApprovalStatus: appStatusID,
            DateFrom: dateFrom,
            DateTo: dateTo,
            EmployeeID: employee
        });
    };

    const onSelectionChanged = (e) => {
        if (!e) return false;
        loadFormInfo(e?.VoucherID || e?.TransGroupID || "");
    };

    useEffect(() => {
        loadPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (iPermission > 0) {
            onLoadCboFilter();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[iPermission])

    if (!iPermission) return null;
    return (
        <>
            <ActionToolbar
                title={Config.lang("Duyet_de_xuat_cap_phat_trang_thiet_bi_lao_dong")}
            />
            <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                    <Approvals
                        allowHistoryApproval
                        parentProps={props}
                        FormID={FormID}
                        dataForm={dataForm}
                        dataFilter={dataFilter}
                        selectedRowKey={voucherID}
                        singleClick
                        filterRender={renderFilter}
                        formInfoRender={renderFormInfo}
                        onSelectionChanged={onSelectionChanged}
                        onDrawerClosed={onDrawerClosed}
                        disableEscapeKeyDown={false}
                    />
                </Col>
            </Row>
        </>
    )
}
