/**
 * @copyright 2023 @ DigiNet
 * @author THANHAN
 * @create 4/1/2023
 * @Example
 */

import React, { useState, useRef, useEffect } from 'react'
import Config from '../../../../config';
import { useDispatch } from "react-redux";
import * as generalActions from '../../../../redux/general/general_actions';
import * as W51F1001Actions from '../../../../redux/W5X/W51F1001/W51F1001_actions';
import * as W51F2201Actions from '../../../../redux/W5X/W51F2201/W51F2201_actions';
import _ from "lodash";
import moment from "moment";
import History from "../../../libs/history";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import { MForm } from "../../../common/form-material";
import GridContainer from "../../../grid-container/grid-container";
import GridActionBar from "../../../grid-container/grid-actionbar";
import HeadClick from "../../../grid-container/head-click";
import W09F6000 from "../../../W0X/W09/W09F6000/W09F6000";
import W51F1002 from "../W51F1002/W51F1002";
import { Loading } from "../../../common/loading/loading";
import { Column, RequiredRule } from "devextreme-react/data-grid";
import {
    Row,
    Col,
    Button,
    Dropdown,
    Attachment,
    DatePicker,
    Typography,
    ButtonIcon,
    Icon,
    NumberInput,
    FormGroup,
} from "diginet-core-ui/components";
import CDN from "../../../CDN";
export default function W51F2201(props) {
    const { location } = props;
    const dispatch = useDispatch();
    const FormID = "W51F2201";

    const dataFormOld = useRef({});
    const refDataGridEmployee = useRef();
    const refDataGridEquipment = useRef();
    // attachments ref
    const attachments = useRef([]);
    const attRef = useRef(null);
    const deletedFile =useRef([]);

    let selectedRange = useRef(null);
    const employeeGridParamPaging = useRef({ limit: 10, skip: 0 });
    const equipmentGridParamPaging = useRef({ limit: 10, skip: 0 });
    const timer = useRef();
    const [currentEmployee, setCurrentEmployee] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingAttachments, setLoadingAttachments] = useState(false);
    const [isShowModalEmployeeChosen, setShowModalEmployeeChosen] = useState(false);
    const [showW51F1002Popup, setShowW51F1002Popup] = useState(false);

    // data
    const [dataForm, setDataForm] = useState(
        {
            Employee: [],
            Equipment: [],
            Master: [],
        }
    );

    const [dataOldAttachments, setDataOldAttachments] = useState([]);
    const [dataGridEmployee, setDataGridEmployee] = useState([]); // data luoi 1
    const [dataGridEquipment, setDataGridEquipment] = useState([]); // data luoi 2
    const [dataCboWarehouse, setDataCboWarehouse] = useState([]); // data dropdown kho
    const [dataCboReason, setDataCboReason] = useState([]); // data dropdown ly do
    const [dataCboSize, setDataCboSize] = useState([]); // data dropdown size luoi 2
    const [dataNormName, setDataNormName] = useState([]); // data
    const [isCalEliNorms, setIsCalEliNorms] = useState();

    const [warehouseID, setWarehouseID] = useState("");
    const [reasonID, setReasonID] = useState("");
    const [voucherDate, setVoucherDate] = useState(moment().format("YYYY-MM-DD"));
    const [numValue, setNumValue] = useState(0);
    const [normID, setNormID] = useState([]);
    const [parentScreen, setParentScreen] = useState("");
    const [errorHelperText, setErrorHelperText] = useState({});
    const [employeeIDNotAllocated, setEmployeeIDNotAllocated] = useState([]);

    const setStateErrorText = (objValue) => {
        if (objValue) {
            setErrorHelperText({
                ...errorHelperText,
                ...objValue
            });
            return Object.keys(objValue).length !== 0;
        } else {
            setErrorHelperText({});
            return false;
        }
    };

    const getInfo = () => {
        const url = new window.URLSearchParams(window.location.search);
        if (url && url.get('TransGroupID')) {
            return {
                mode: url.get('Mode'),
                AppStatusID: url.get('AppStatusID'),
                TransGroupID: url.get('TransGroupID'),
                Equipment: url.get('selectedItems'),
                issueEquipment: url.get("issueEquipment"),
                FormParent: url.get('FormParent'),
            };
        } else if (location && location.state) {
            return {
                mode: _.get(location, "state.mode", ""),
                AppStatusID: _.get(location, "state.AppStatusID", 0),
                TransGroupID: _.get(location, "state.TransGroupID", ""),
                Equipment: _.get(location, "state.selectedItems", []),
                issueEquipment: _.get(location, "state.issueEquipment.recordsets", []),
                FormParent: _.get(location, "state.FormParent", ""),
            }
        } else {
            return {};
        }
    };


    const disabled = Number(getInfo()?.AppStatusID) !== 0 || getInfo()?.mode === "view";

    const handleChange = (e, target) => {
        const value = e?.value ?? e?.target?.value ?? "";
        switch (target) {
            case "warehouseID":
                if (!_.isEmpty(dataGridEquipment)) {
                    /**
                     *  Kiểm tra lưới 2 có tồn tại một dòng dữ liệu thì hiện thông báo Yes/No: 
                     *  “Thay đổi kho sẽ xóa trắng lưới công cụ lao động.
                     *  Bạn có muốn thay đổi không?”
                     */
                    Config.popup.show(
                        "YES_NO",
                        `${Config.lang("Thay_doi_kho_se_xoa_trang_luoi_cong_cu_lao_dong")}.${Config.lang("Ban_co_muon_thay_doi_khong")}`,
                        () => {
                            setWarehouseID(value);
                            setDataForm({ ...dataForm, Equipment: [...dataForm.Equipment?.filter(p => p.EmployeeID !== currentEmployee.EmployeeID)] })
                        }, () => {
                            return;
                        }
                    )
                }
                else {
                    setWarehouseID(value);
                }
                break;
            case "reasonID":
                setReasonID(value);
                break;
            case "voucherDate":
                setVoucherDate(value);
                break;
            case "normID":
                setNormID(value);
                break;
            default: break;
        }
    };

    const onChangeAttachments = (e) => {
        attachments.current = e.allNewAttached ? e.allNewAttached : [];
        if (e.removedAttached && e.removedAttached.length > 0) {
            deletedFile.current = [ ...e.removedAttached];
        }
    };

    const _getAttachments = (file) => {
        const dataFile = _.get(file, 'data.paths', []);
        const listAttachments = Config.helpers.getFileInfomations(dataFile);
        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileName ? att.fileName.split('.').pop() : ""
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    const _removeCDN = () => {
        if (deletedFile.current && deletedFile.current.length > 0) {
            deletedFile.current.forEach((e) => {
                const path = e.URL.split("=");
                if (path && path.length > 1) {
                    const params = {
                        path: path[1]
                    };
                    CDN.removeFile(params);
                }
            });
            deletedFile.current= [];
        }
    };

    const _uploadFile = (files, isAsync, cb) => {
        if (isAsync) {
            return CDN.uploadFileSync(files);
        } else {
            return CDN.uploadFile(files, null, cb);
        }
    };

    // load data
    const loadCboWarehouse = () => {
        const params = {
            FormID: FormID,
            Languague: "84",
        }
        setLoading(true);
        dispatch(generalActions.getCboWarehouse(params, (err, data) => {
            setLoading(false);
            if (err) {
                return Config.popup.show('ERROR', err);
            }
            else if (data) {
                setDataCboWarehouse(data);
                if (_.isEmpty(warehouseID)) {
                    setWarehouseID(data[0]?.WareHouseID);
                }
            }
        }))
    };

    const getCboReason = () => {
        setLoading(true);
        dispatch(W51F1001Actions.getCbReason((err, data) => {
            setLoading(false);
            if (err) {
                return Config.popup.show('ERROR', err);
            }
            else if (data) {
                setDataCboReason(data);
            }
        }))
    };

    const getWareHouse = () => {
        setLoading(true);
        dispatch(W51F1001Actions.getWareHouse((error, data) => {
            setLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            else if (data) {
                setNumValue(data?.NumValue);
            }
        }))
    };

    const getCboSize = () => {
        setLoading(true);
        dispatch(W51F1001Actions.getCbSize((err, data) => {
            setLoading(false);
            if (err) {
                return Config.popup.show('ERROR', err);
            }
            else if (data) {
                setDataCboSize(data);
            }
        }))
    };

    const loadNormName = () => {
        setLoading(true);
        dispatch(W51F2201Actions.loadNormName((err, data) => {
            setLoading(false);
            if (err) {
                return Config.popup.show('ERROR', err);
            }
            else if (data) {
                setDataNormName(data);
            }
        }))
    };

    const getIsCalEliNorms = () => {
        setLoading(true);
        dispatch(W51F2201Actions.getIsCalEliNorms((err, data) => {
            setLoading(false);
            if (err) {
                return Config.popup.show('ERROR', err);
            }
            else if (data) {
                if (!_.isEmpty(data) && _.isArray(data)) {
                    setIsCalEliNorms(data[0]?.IsCalEliNorms);
                }
            }
        }))
    };

    const setDefaultValue = (data) => {
        if (!data) return;
        const today = moment().format("YYYY-MM-DD");
        data.forEach(p => {
            p.UsingPeriodFrom = p?.UsingPeriodFrom ? moment(p?.UsingPeriodFrom).format("YYYY-MM-DD") : today;
            p.UsingPeriod = p?.UsingPeriod ? p?.UsingPeriod : 1;
            p.VoucherNotes = p?.VoucherNotes ? p?.VoucherNotes : "";
        });
    }

    const loadForm = (screenID) => {
        const { TransGroupID, Equipment, mode } = getInfo();
        if (!_.isEmpty(Equipment)) screenID = "W82F1000";
        const params = {
            Mode: screenID ? 1 : 0,
            FormID,
            TransGroupID,
        }
        setLoading(true);
        dispatch(W51F2201Actions.loadForm(params, (err, data) => {
            setLoading(false);
            if (err) {
                return Config.popup.show('ERROR', err);
            }
            else if (data) {
                if (!screenID) {
                    const today = moment().format("YYYY-MM-DD");
                    if (mode !== "add") {
                        setWarehouseID(data?.Master?.WareHouseID ?? "");
                        setReasonID(data?.Master?.ReasonID ?? "");
                        setVoucherDate(data?.Master?.VoucherDate || today);
                        setNormID(data?.Master?.NormID ?? []);
                    }
                    setDataForm(data);
                    dataFormOld.current = JSON.parse(JSON.stringify(data));
                }
                else if (screenID) {
                    setParentScreen(screenID);
                    switch (screenID) {
                        case "W09F6000":
                            /**
                             * Gọi SP W51P2201 @Mode = 1 để lấy thêm các thông tin,
                             * gán danh sách nhân viên lên lưới 1,
                             * chỉ gán thêm những nhân viên mà lưới 1 chưa có,
                             * không gán đè hoặc xóa.
                             */
                            const employeeIDs = new Set(dataForm.Employee.map(p => p.EmployeeID));
                            setDefaultValue(data);
                            setDataForm({ ...dataForm, Employee: [...dataForm.Employee, ...data.filter(p => !employeeIDs.has(p.EmployeeID))] });
                            break;
                        case "W82F1000":
                            const dataW82F1000 = getInfo().Equipment;
                            setDefaultValue(dataW82F1000);
                            setDataForm({ ...dataForm, Employee: data, Equipment: dataW82F1000 })
                            dataFormOld.current = JSON.parse(JSON.stringify({ ...dataFormOld.current, Employee: data, Equipment: dataW82F1000 }));
                            break;
                        default:
                            break;
                    }
                }
            }
        }))
    }

    const loadAttachments = () => {
        const { TransGroupID } = getInfo();
        const param = {
            KeyID: TransGroupID,
        };
        setLoadingAttachments(true);
        dispatch(generalActions.getAttachmentsByTransID(param, (error, data) => {
            setLoadingAttachments(false)
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            else if (data && data.length > 0) {
                setDataOldAttachments(data);
            }
        }))
    };

    // button function
    const onCalculate = () => {
        const params = {
            FormID,
            WareHouseID: warehouseID,
            NormID: _.isArray(normID) ? normID.join(",") : normID,
            data: JSON.stringify(dataGridEmployee),
        }
        setLoading(true);
        dispatch(W51F2201Actions.calculate(params, (err, data) => {
            setLoading(false);
            if (err) {
                Config.popup.show("ERROR", err);
                setDataForm({ ...dataForm, Equipment: [] });
            }
            else if (data) {
                setDataForm({ ...dataForm, Equipment: data.recordset });
            }
        }))
    }

    const onSave = async () => {
        refDataGridEquipment.current.instance.saveEditData();
        const { TransGroupID, mode } = getInfo();
        const { Equipment } = dataForm;
        const dataResUploadFile = await _uploadFile(attachments.current, true);
        const deletedList = new Set(deletedFile.current.map(p=> p.FileName));
        const arrAttachment = _getAttachments(dataResUploadFile).filter(p => !(deletedList.has(p.FileName)));

        let params = {
            FormID,
            WareHouseID: warehouseID ? warehouseID : "",
            ReasonID: reasonID ? reasonID : "",
            VoucherDate: voucherDate ? Config.convertDate(voucherDate, "", "YYYY-MM-DD", false) : moment().format("YYYY-MM-DD"),
            NormID: _.isArray(normID) ? normID.join(",") : normID,
            HREmployeeID: Config.getHREmployeeID(),
            employee: JSON.stringify(Equipment) ?? [],
            arrAttachment,
        };
        if (!_.isEmpty(TransGroupID)) params = { ...params, TransGroupID }

        const validateRules = [
            {
                name: "VoucherDate",
                rules: "isRequired",
                value: voucherDate
            },
            {
                name: "WareHouseID",
                rules: "isRequired",
                value: warehouseID
            }
        ]
        const validateForm = MForm.formValidation(validateRules);
        if (Object.keys(validateForm).length > 0) {
            setStateErrorText(validateForm);
            if (setStateErrorText(validateForm)) return false;
        }

        let employeeIDList = [...dataForm.Employee].map(e => e.EmployeeID);
        let employeeAvailable = new Set([...dataForm.Equipment].map(e => e.EmployeeID));
        let employeeNotAvailable = [...new Set(employeeIDList.filter(p => !employeeAvailable.has(p)))];
        if (!_.isEmpty(employeeNotAvailable)) {
            /**
             * Kiểm tra nhân viên chưa được cấp phát:
             * Nếu có nhân viên chưa được cấp phát dữ liệu ở Grid 2. 
             * Thì xuất thông báo Yes/No“Tồn tại nhân viên chưa được cấp phát. Bạn có muốn lưu không ?”
             * Người dùng click Yes thì lưu
             * Người dùng click No thì không lưu mà gắn icon Warning trên dòng lưới 1 mà nhân viên chưa được cấp phát 
             */
            Config.popup.show(
                "YES_NO",
                `${Config.lang("Ton_tai_nhan_vien_chua_duoc_cap_phat")}`,
                () => {
                    setEmployeeIDNotAllocated([]);
                    setLoading(true);
                    dispatch(W51F2201Actions[mode](params, async (err, data) => {
                        setLoading(false);
                        if (err) {
                            Config.popup.show("ERROR", err);
                        }
                        else if (data) {
                            if (data?.Status === 1) {
                                Config.popup.show("INFO", Config.lang(data?.Message), () => {
                                    return;
                                });
                            }
                            else if (data?.Status === 0) {
                                _removeCDN();
                                Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
                                mode === "add" ? await saveHistory(data?.TransGroupID) : await saveHistory();
                            }
                        }
                    }))
                },
                () => {
                    setEmployeeIDNotAllocated(employeeNotAvailable);
                    return;
                }
            )
        }
        else {
            setLoading(true);
            dispatch(W51F2201Actions[mode](params, async (err, data) => {
                setLoading(false);
                if (err) {
                    Config.popup.show("ERROR", err);
                }
                else if (data) {
                    if (data?.Status === 1) {
                        Config.popup.show("INFO", Config.lang(data?.Message), () => {
                            return;
                        });
                    }
                    else if (data?.Status === 0) {
                        _removeCDN();
                        Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
                        mode === "add" ? await saveHistory(data?.TransGroupID) : await saveHistory();
                    }
                }
            }))
        }
    }

    const saveHistory = async (TransID) => {
        const { mode, TransGroupID } = getInfo();
        const action = mode === "add" ? 0 : 1;
        let currentTransID = mode === "add" ? TransID : TransGroupID;
        if (currentTransID) {
            const dataEquipment = JSON.parse(JSON.stringify(dataForm.Equipment));
            let _oldEquipment = []
            if (mode !== "add") {
                _oldEquipment = JSON.parse(JSON.stringify(dataFormOld.current.Equipment));
                if (!_.isEmpty(_oldEquipment)) {
                    for (let i = 0; i < _oldEquipment.length; i++) {
                        _oldEquipment[i].UsingPeriodFrom = Config.convertDate(_oldEquipment[i].UsingPeriodFrom, null, "DD/MM/YYYY", false);
                        _oldEquipment[i].UsingPeriodTo = Config.convertDate(_oldEquipment[i].UsingPeriodTo, null, "DD/MM/YYYY", false);
                    }
                }
            }
            if (!_.isEmpty(dataEquipment)) {
                for (let i = 0; i < dataEquipment.length; i++) {
                    dataEquipment[i].UsingPeriodFrom = Config.convertDate(dataEquipment[i].UsingPeriodFrom, null, "DD/MM/YYYY", false);
                    dataEquipment[i].UsingPeriodTo = Config.convertDate(dataEquipment[i].UsingPeriodTo, null, "DD/MM/YYYY", false);
                }
            }

            const _oldMaster = dataFormOld.current.Master;

            const _dataCompare = {
                WareHouse: _oldMaster?.WareHouseID,
                Reason: _oldMaster?.ReasonID,
                VoucherDate: moment(_oldMaster?.VoucherDate).format("DD/MM/YYYY"),
                NormID: _.isArray(_oldMaster?.NormID) ? _oldMaster?.NormID.join(",") : _oldMaster?.NormID,
            };
            const _data = {
                WareHouse: warehouseID,
                Reason: reasonID,
                VoucherDate: moment(voucherDate).format("DD/MM/YYYY"),
                NormID: _.isArray(normID) ? normID.join(",") : normID,
            }
            const captions = {
                WareHouse: "Kho",
                Reason: "Ly_do",
                VoucherDate: "Ngay_de_xuat",
                NormID: "Dinh_muc_CCLD",
            }

            let detailCaptions = {
                EmployeeID: "nhan_vien",
                EquipmentGroupID: "nhom_CCLD",
                EquipmentID: "CCLD",
                SizeID: "Size",
                ProposalQTY: "so_luong",
                UsingPeriod: "so_thang_su_dung",
                UsingPeriodFrom: "thoi_han_su_dung_tu",
                UsingPeriodTo: "thoi_han_su_dung_den",
                VoucherNotes: "ghi_chu",
                NormID: "dinh_muc_CCLD"
            };

            let options = {
                data: _data,
                dataCompare: _dataCompare,
                captions: captions,
                action,
                ModuleID: "D51",
                TransID: currentTransID,
                TransactionID: FormID,
                TransactionName: Config.lang("Lap_de_xuat_cap_phat_trang_thiet_bi_lao_dong"),
            };
            const history = new History(options);
            history.createDetailHistory("Danh_sach_de_xuat_cap_phat", dataEquipment, _oldEquipment, detailCaptions, "ProTransID", null, options); //Create detail..
            if (history.get().length > 0) {
                await history.save();
                dataFormOld.current = JSON.parse(JSON.stringify(dataForm));
            }
        }
        else {
            Config.notify.show("error", Config.lang("DHR_Luu_lich_su_khong_thanh_cong"), 2000);
        }
    };

    // modal function
    const onChosenEmployees = () => {
        loadForm("W09F6000");
        setShowModalEmployeeChosen(false);
    };

    const onCloseModalEmployee = () => {
        setShowModalEmployeeChosen(false);
    }

    const closePopupAllocation = () => {
        setShowW51F1002Popup(false);
    };

    const onChosenAllocation = (data) => {
        setDefaultValue(data);
        data.forEach(data => {
            data.EquipmentGroupID = data?.EquipmentGroupID ? data?.EquipmentGroupID : "";
            data.ProposalQTY = data?.ProposalQTY ? data?.ProposalQTY : 1;
            data.NormID = _.isArray(normID) ? normID.join(",") : normID;
            data.SizeID = data?.SizeID ? data?.SizeID : "";
            data.UsingPeriod = data?.UsingPeriod ? data?.UsingPeriod : null;
            data.EmployeeID = currentEmployee.EmployeeID;
        });
        const equipmentIDs = new Set(dataForm.Equipment.filter(p => p.EmployeeID === currentEmployee.EmployeeID).map(p => p.EquipmentID));
        const newEquipmentData = [...dataForm.Equipment, ...data.filter(p => (!equipmentIDs.has(p.EquipmentID)))]
        setDataForm({ ...dataForm, Equipment: newEquipmentData });
        setShowW51F1002Popup(false);
    };
    // grid function

    const onChangePageEmployee = (page) => {
        employeeGridParamPaging.current.skip = page * employeeGridParamPaging.current.limit;
    };

    const onChangePerPageEmployee = (per) => {
        employeeGridParamPaging.current.skip = 0;
        employeeGridParamPaging.current.limit = per;
    };

    const onChangePageEquipment = (page) => {
        equipmentGridParamPaging.current.skip = page * equipmentGridParamPaging.current.limit;
    };

    const onChangePerPageEquipment = (per) => {
        equipmentGridParamPaging.current.skip = 0;
        equipmentGridParamPaging.current.limit = per;
    };

    const onDeleteEquipment = (data) => {
        const { EquipmentID } = data;
        setDataForm(
            {
                ...dataForm,
                Equipment: [...dataForm.Equipment.filter(
                    p => !(p.EquipmentID === EquipmentID && p.EmployeeID === currentEmployee.EmployeeID)
                )]
            }
        )
    }

    const onDeleteEmployee = () => {
        if (!_.isEmpty(dataGridEquipment)) {
            Config.popup.show("INFO", Config.lang("Ton_tai_nhan_vien_dang_duoc_cap_phat_CCLD"),
                () => {
                    return;
                });
        }
        else {
            setDataForm({ ...dataForm, Employee: [...dataForm.Employee?.filter(p => p.EmployeeID !== currentEmployee.EmployeeID)] });
        }
    }

    const renderAction = (e, grid) => {
        if (!e) return;
        const { data } = e;
        return (
            <GridActionBar>
                <div className="">
                    <ButtonIcon
                        name="delete"
                        viewType="text"
                        size="small"
                        circular
                        disabled={disabled}
                        onClick={() => grid === 0 ? onDeleteEmployee() : onDeleteEquipment(data)}
                    />
                </div>
            </GridActionBar>
        )
    };

    const renderEmpID = (e) => {
        if (!e) return;
        const { data } = e.row;
        const employeeId = _.get(data, "EmployeeID", "");
        let renderIconWarning = "";
        if (_.includes(employeeIDNotAllocated, _.get(data, "EmployeeID", ""))) {
            renderIconWarning = (
                <Icon name="Warning" color="warning" height={24} width={24} />
            );
        }
        return (
            <div style={{ display: "flex" }}>
                {renderIconWarning}
                {employeeId}
            </div>
        );
    };

    const renderSizeCell = (e) => {
        if (!e) return;
        const { component, rowIndex, data } = e;
        return (
            <Dropdown
                dataSource={dataCboSize.filter(p => p.SizeGroupID === data.SizeGroupID)}
                valueExpr="SizeID"
                displayExpr="SizeName"
                defaultValue={data?.SizeID}
                closeAfterSelect
                style={{ marginBottom: 0 }}
                viewType="none"
                onChange={(c) => {
                    component.cellValue(rowIndex, "SizeID", c?.data?.SizeID);
                    e.setValue(c?.data.SizeName);
                    component.saveEditData();
                }}
            />
        )
    };

    const renderQuantity = (e) => {
        if (!e) return;
        const { data } = e;
        return (
            <NumberInput
                value={data?.ProposalQTY}
                decimalDigit={2}
                disabledNegative
                decimalSymbol={"."}
                viewType="none"
                style={{ marginBottom: 0 }}
                onChange={(c) => {
                    e.setValue(c?.value)
                }}
            />
        )
    }

    const renderUsingPeriod = (e) => {
        const { component, rowIndex, data } = e;
        const { UsingPeriod, UsingPeriodFrom, UsingPeriodTo } = data;
        return (
            <NumberInput
                value={UsingPeriod}
                disabledNegative
                viewType="none"
                style={{ marginBottom: 0 }}
                onChange={(c) => {
                    e.setValue(c?.value);
                    if (timer.current) clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                        if (UsingPeriodFrom !== null) {
                            const usingPeriodTo = moment(UsingPeriodFrom)
                                .add(c?.value, "month")
                                .format("YYYY-MM-DD");
                            component.cellValue(rowIndex, "UsingPeriodTo", usingPeriodTo);
                        }
                        else if (UsingPeriodTo !== null) {
                            const usingPeriodFrom = moment(UsingPeriodTo)
                                .subtract(c?.value, "month")
                                .add(1, "day")
                                .format("YYYY-MM-DD");
                            component.cellValue(rowIndex, "UsingPeriodFrom", usingPeriodFrom);
                        }
                        component.saveEditData();
                    }, 500)
                }}
            />
        )
    }

    const renderUsingPeriodFrom = (e) => {
        const { component, rowIndex, data } = e;
        const { UsingPeriod, UsingPeriodFrom, UsingPeriodTo } = data;
        return (
            <DatePicker
                value={UsingPeriodFrom || null}
                max={voucherDate ? voucherDate : null}
                displayFormat={"DD/MM/YYYY"}
                style={{ marginBottom: 0 }}
                onChange={c => {
                    if (UsingPeriod) {
                        const usingPeriodTo = moment(c?.value)
                            .add(UsingPeriod, "months")
                            .format("YYYY-MM-DD");
                        const usingPeriodFrom = moment(c?.value)
                            .format("YYYY-MM-DD");
                        component.cellValue(rowIndex, "UsingPeriodFrom", usingPeriodFrom);
                        component.cellValue(rowIndex, "UsingPeriodTo", usingPeriodTo);
                    }
                    else if (UsingPeriodTo !== null) {
                        if (c.value === null) return;
                        const usingPeriod = moment(UsingPeriodTo)
                            .add(1, "day")
                            .diff(moment(c?.value), "months")
                        const usingPeriodFrom = moment(c?.value)
                            .format("YYYY-MM-DD");
                        component.cellValue(rowIndex, "UsingPeriodFrom", usingPeriodFrom);
                        component.cellValue(rowIndex, "UsingPeriod", usingPeriod);
                    }
                    component.saveEditData();
                }}
            />
        )
    }

    const renderUsingPeriodTo = (e) => {
        const { component, rowIndex, data } = e;
        const { UsingPeriod, UsingPeriodFrom, UsingPeriodTo } = data;
        return (
            <DatePicker
                value={UsingPeriodTo || null}
                min={UsingPeriodFrom ? UsingPeriodFrom : null}
                displayFormat={"DD/MM/YYYY"}
                style={{ marginBottom: 0 }}
                onChange={c => {
                    if (UsingPeriodFrom !== null) {
                        const usingPeriod = moment(c?.value)
                            .add(1, "day")
                            .diff(moment(UsingPeriodFrom), "months");
                        const usingPeriodTo = moment(c?.value)
                            .format("YYYY-MM-DD");
                        component.cellValue(rowIndex, "UsingPeriodTo", usingPeriodTo);
                        component.cellValue(rowIndex, "UsingPeriod", usingPeriod);
                    }
                    else if (UsingPeriod) {
                        const usingPeriodFrom = moment(UsingPeriodTo)
                            .subtract(UsingPeriod, "months")
                            .format("YYYY-MM-DD");
                        const usingPeriodTo = moment(c?.value)
                            .format("YYYY-MM-DD");
                        component.cellValue(rowIndex, "UsingPeriodTo", usingPeriodTo);
                        component.cellValue(rowIndex, "UsingPeriodFrom", usingPeriodFrom);
                    }
                    component.saveEditData();
                }}
            />
        )
    }

    const renderHeaderColumns = e => {
        if (!e) return false;
        return (
            <HeadClick
                selectedRange={selectedRange.current}
                dataHeaderCell={e}
                allowClick={!disabled}
                onUpdate={(data, col) => {
                    let newData = [];
                    switch (col.dataField) {
                        case "UsingPeriod":
                            const getNewUsingPeriod = _.get(data, "UsingPeriod", null);
                            newData = _.map(dataGridEquipment, item => {
                                let fieldUpdate;
                                const getUsingPeriod = _.get(item, "UsingPeriod", null);
                                const dataUpdated = handleUpdateCell("UsingPeriod", getNewUsingPeriod, getUsingPeriod);
                                fieldUpdate = { UsingPeriod: dataUpdated.value };
                                return ({ ...item, ...fieldUpdate })
                            })
                            break;
                        case "UsingPeriodFrom":
                            const getNewUsingPeriodFrom = _.get(data, "UsingPeriodFrom", null);
                            newData = _.map(dataGridEquipment, item => {
                                let fieldUpdate;
                                const getUsingPeriodFrom = _.get(item, "UsingPeriodFrom", null);
                                const dataUpdated = handleUpdateCell("UsingPeriodFrom", getNewUsingPeriodFrom, getUsingPeriodFrom);
                                fieldUpdate = { UsingPeriodFrom: dataUpdated.value };
                                return ({ ...item, ...fieldUpdate })
                            });
                            break;
                        case "ProposalQTY":
                            const getNewProposalQTY = _.get(data, "ProposalQTY", null);
                            newData = _.map(dataGridEquipment, item => {
                                let fieldUpdate;
                                const getProposalQTY = _.get(item, "ProposalQTY", null);
                                const dataUpdated = handleUpdateCell("ProposalQTY", getNewProposalQTY, getProposalQTY);
                                fieldUpdate = { ProposalQTY: dataUpdated.value };
                                return ({ ...item, ...fieldUpdate })
                            })
                            break;
                        default:
                            break;
                    }
                    if (!_.isEmpty(newData)) {
                        const newDataForm = [...dataForm.Equipment.filter(p => p.EmployeeID !== currentEmployee.EmployeeID)]
                        setDataForm({ ...dataForm, Equipment: [...newDataForm, ...newData] })
                        setDataGridEquipment(newData);
                    }
                }}
            />
        )
    };

    const handleUpdateCell = (field, data1, data2) => {
        let newValue = "";
        switch (field) {
            case "ProposalQTY": {
                newValue = data1;
                if (!_.isNumber(newValue)) newValue = 0;
                break;
            }
            case "UsingPeriod": {
                newValue = data1;
                if (!_.isNumber(newValue)) newValue = 0;
                break;
            }
            case "UsingPeriodFrom": {
                newValue = data1;
                break;
            }
            default:
                break
        }
        return { dataField: field, value: newValue }
    }

    const onSelectionChanged = (e) => {
        if (!e) return;
        else {
            const selectedRow = e.selectedRowsData[0];
            refDataGridEquipment.current.instance.saveEditData();
            setCurrentEmployee(selectedRow);
        }
    };

    // useEffect
    useEffect(() => {
        const { mode, FormParent } = getInfo();
        loadCboWarehouse();
        getCboReason();
        getCboSize();
        getWareHouse();
        loadNormName();
        getIsCalEliNorms();
        if (mode === "add") {
            if (FormParent === "W51F2200") {
                return;
            }
            else {
                loadForm();
            }
        }
        else if (mode === "edit" || mode === "view") {
            loadForm();
            loadAttachments();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const { Employee } = dataForm;
        if (!_.isEmpty(Employee)) {
            setCurrentEmployee(dataForm?.Employee[0]);
            setDataGridEmployee(dataForm?.Employee);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataForm.Employee, employeeGridParamPaging.current])

    useEffect(() => {
        const { Equipment } = dataForm;
        const filteredData = Equipment?.filter(p => p.EmployeeID === currentEmployee?.EmployeeID);
        const sortedData = _.sortBy(filteredData, ["EquipmentGroupID", "EquipmentID"]);
        setDataGridEquipment(sortedData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEmployee, dataForm.Equipment])


    return (
        <>
            {isShowModalEmployeeChosen &&
                <W09F6000
                    open={isShowModalEmployeeChosen}
                    FormID={"W51F2201"}
                    onChosen={onChosenEmployees}
                    onClose={onCloseModalEmployee}
                />
            }
            {showW51F1002Popup &&
                <W51F1002
                    open={showW51F1002Popup}
                    onClose={closePopupAllocation}
                    onChosen={onChosenAllocation}
                    isMode={1}
                    FormID={"W51F1002"}
                    WareHouseIDDef={warehouseID}
                />}
            {loading && <Loading />}
            <FormGroup className="mgb4x">
                <ActionToolbar
                    title={Config.lang("Lap_de_xuat_cap_phat_trang_thiet_bi_lao_dong")}
                    alignment="space-between"
                >
                    <Button
                        disabled={disabled || parentScreen === "W82F1000"}
                        label={Config.lang("Chon_nhan_vien")}
                        startIcon="AddPerson"
                        viewType="filled"
                        color="primary"
                        onClick={() => setShowModalEmployeeChosen(true)}
                    />
                    <Button
                        disabled={disabled || loadingAttachments}
                        label={Config.lang("Luu")}
                        startIcon="save"
                        viewType="filled"
                        color="info"
                        onClick={onSave}
                    />
                </ActionToolbar>
            </FormGroup>
            <Row>
                <Col xs={12} sm={12} md={3}>
                    <Dropdown
                        required={numValue === 1}
                        label={Config.lang("Kho")}
                        dataSource={dataCboWarehouse ?? []}
                        value={warehouseID}
                        displayExpr="WareHouseName"
                        valueExpr="WareHouseID"
                        onChange={e => handleChange(e, "warehouseID")}
                        error={errorHelperText && errorHelperText['WareHouseID']}
                        readOnly={numValue === 0 || getInfo()?.mode !== "add"}
                    />
                </Col>
                <Col xs={12} sm={12} md={6}>
                    <Dropdown
                        label={Config.lang("Ly_do")}
                        dataSource={dataCboReason}
                        value={reasonID}
                        displayExpr="ReasonName"
                        valueExpr="Reason"
                        onChange={e => handleChange(e, "reasonID")}
                    />
                </Col>
                <Col xs={12} sm={12} md={3}>
                    <DatePicker
                        required
                        label={Config.lang("Ngay_de_xuat")}
                        value={voucherDate}
                        onChange={e => handleChange(e, "voucherDate")}
                        error={errorHelperText && errorHelperText['VoucherDate']}
                        displayFormat='DD/MM/YYYY'
                        placeholder='DD/MM/YYYY'
                    />
                </Col>
                <Col xs={12} sm={12} md={9}>
                    <Dropdown
                        label={Config.lang("Dinh_muc_CCLD")}
                        dataSource={dataNormName}
                        value={_.isString(normID) && !_.isEmpty(normID) ? normID.split(',') : normID}
                        onChange={e => handleChange(e, "normID")}
                        valueExpr="NormID"
                        displayExpr="{NormID} - {NormName}"
                        multiple
                        disabled={
                            isCalEliNorms === 0
                        }
                    />
                </Col>
                <Col xs={12} sm={12} md={3}>
                    <Button
                        label={Config.lang("Tinh")}
                        disabled={
                            isCalEliNorms === 0 || // Disable khi IsCalEliNorms = 0
                            parentScreen === "W82F1000" || // Hoặc gọi từ W82F1000 với @AlertBaseID = 'D82A5104'
                            disabled || //Hoặc @AppStatusID <> 0
                            _.isEmpty(normID) ||  //Hoặc NormID = []
                            _.isEmpty(dataGridEmployee) //Hoặc Lưới 1 nhân viên trống
                        }
                        startIcon="calculate"
                        onClick={onCalculate}
                    />
                </Col>
                {/* luoi 1 */}
                <Col xs={12} sm={12} md={12}>
                    <GridContainer
                        reference={ref => refDataGridEmployee.current = ref}
                        keyExpr="EmployeeID"
                        dataSource={dataForm.Employee ?? []}
                        itemPerPage={employeeGridParamPaging.current.limit}
                        skipPerPage={employeeGridParamPaging.current.skip}
                        totalItems={dataForm?.Employee?.length ?? 0}
                        onChangePage={onChangePageEmployee}
                        onChangePerPage={onChangePerPageEmployee}
                        typePaging="normal"
                        pagerFullScreen={false}
                        filterRow={{
                            visible: true,
                            showOperationChooser: false,
                        }}
                        onSelectionChanged={onSelectionChanged}
                        onContentReady={(e) => {
                            let grid = e.component;
                            let selection = grid.getSelectedRowKeys();
                            if (selection.length === 0) {
                                grid.selectRowsByIndexes([0]);
                            }
                        }}
                    >
                        <Column
                            caption={Config.lang("Ma_nhan_vien")}
                            dataField="EmployeeID"
                            width={220}
                            allowEditing={false}
                            cellRender={renderEmpID}
                        />
                        <Column
                            caption={Config.lang("Ten_nhan_vien")}
                            dataField="EmployeeName"
                            width={300}
                            allowEditing={false}
                        />
                        <Column
                            caption={Config.lang("Size_ao")}
                            dataField={"ShirtSizeName"}
                            alignment="center"
                            allowEditing={false}
                        />
                        <Column
                            caption={Config.lang("Size_quan")}
                            dataField="TrousersSizeName"
                            alignment="center"
                            allowEditing={false}
                        />
                        <Column
                            caption={Config.lang("Size_giay")}
                            dataField="ShoesSizeName"
                            alignment="center"
                            allowEditing={false}
                        />
                        <Column
                            caption={Config.lang("Size_do_sach")}
                            dataField="ClothesSizeName"
                            alignment="center"
                            allowEditing={false}
                        />
                        {/* hidden column */}
                        <Column
                            dataField="ShirtSizeNameID"
                            visible={false}
                        />
                        <Column
                            dataField="TrousersSizeID"
                            visible={false}
                        />
                        <Column
                            dataField="ShoesSizeID"
                            visible={false}
                        />
                        <Column
                            dataField="ClothesSizeID"
                            visible={false}
                        />
                        {/* action column */}
                        <Column
                            alignment={"center"}
                            fixed={true}
                            fixedPosition={"right"}
                            cellRender={(e) => renderAction(e, 0)}
                            visible={!Config.isMobile}
                        />
                        <Column
                            width={100}
                            alignment={"right"}
                            cellRender={(e) => renderAction(e, 0)}
                            visible={Config.isMobile}
                        />
                    </GridContainer>
                </Col>
                <Col xs={12} sm={12} md={2}>
                    <Typography type="h3">
                        {`${Config.lang("Thong_tin_de_xuat_cap_phat_cho")}:`}
                    </Typography>
                </Col>
                <Col xs={12} sm={12} md={2}>
                    <Dropdown
                        readOnly
                        value={currentEmployee}
                        dataSource={dataGridEmployee}
                        displayExpr="{EmployeeID} - {EmployeeName}"
                        iconExpr={{ key: 'UserPictureURL', prefix: Config.getCDNPath() }}
                    />
                </Col>
                <Col xs={12} sm={12} md={2}>
                    <Button
                        viewType="text"
                        startIcon="tools"
                        label={Config.lang("Chon_CCLD")}
                        disabled={
                            !_.isEmpty(normID) ||
                            _.isEmpty(currentEmployee)
                        }
                        onClick={() => setShowW51F1002Popup(true)}
                    />
                </Col>
                {/* luoi 2 */}
                <Col xs={12} sm={12} md={12}>
                    <GridContainer
                        keyExpr="EquipmentID"
                        reference={ref => refDataGridEquipment.current = ref}
                        dataSource={dataGridEquipment}
                        typePaging="normal"
                        itemPerPage={equipmentGridParamPaging.current.limit}
                        skipPerPage={equipmentGridParamPaging.current.skip}
                        totalItems={dataGridEquipment?.length ?? 0}
                        pagerFullScreen={false}
                        onChangePage={onChangePageEquipment}
                        onChangePerPage={onChangePerPageEquipment}
                        filterRow={{
                            visible: true,
                            showOperationChooser: false,
                        }}
                        editing={{
                            refreshMode: 'reshape',
                            texts: {
                                confirmDeleteMessage: "",
                            },
                            mode: "cell",
                            allowUpdating: true,
                            selectTextOnEditStart: true,
                            startEditAction: Config.isMobile ? "click" : "dblClick",
                        }}
                        onCellSelectionChanged={(e) => {
                            if (e.selectedRange && e.selectedRange.rowType !== "header") {
                                selectedRange.current = e.selectedRange;
                            }
                        }}
                        onEditorPreparing={e => {
                            if (!e) return;
                            if (
                                e.parentType === "filterRow" &&
                                e.dataField === "InterviewDateText"
                            ) {
                                e.editorOptions.onValueChanged = val => {
                                    e.setValue(val.value);
                                };
                            }
                        }}
                        sorting={{
                            mode: "none"
                        }}
                    >
                        <Column
                            caption={Config.lang("STT")}
                            cellRender={e => equipmentGridParamPaging.current.skip + e.rowIndex + 1}
                            width={100}
                            allowEditing={false}
                        />
                        <Column
                            caption={Config.lang("Nhom_cong_cu_lao_dong")}
                            dataField="EquipmentGroupName"
                            width={180}
                            allowEditing={false}
                        />
                        <Column
                            caption={Config.lang("Cong_cu_lao_dong")}
                            dataField="EquipmentName"
                            width={240}
                            allowEditing={false}
                        />
                        <Column
                            caption={Config.lang("Size")}
                            dataField="SizeName"
                            editCellRender={(e) => renderSizeCell(e)}
                            alignment={"center"}
                            width={200}
                            allowEditing={true}
                        />
                        <Column
                            caption={Config.lang("Don_vi_tinh")}
                            dataField="UnitName"
                            width={200}
                            allowEditing={false}
                        />
                        <Column
                            caption={Config.lang("So_luong")}
                            dataField="ProposalQTY"
                            alignment="right"
                            width={180}
                            headerCellRender={renderHeaderColumns}
                            allowEditing={true}
                            editCellRender={renderQuantity}
                        >
                            <RequiredRule message={Config.lang("Truong_nay_bat_buoc_nhap")} />
                        </Column>
                        <Column
                            caption={Config.lang("So_thang_su_dung")}
                            dataField="UsingPeriod"
                            alignment="right"
                            width={180}
                            headerCellRender={renderHeaderColumns}
                            editCellRender={renderUsingPeriod}
                            allowEditing={true}
                        />
                        <Column
                            caption={Config.lang("Thoi_han_su_dung_tu")}
                            dataField="UsingPeriodFrom"
                            alignment="right"
                            width={230}
                            dataType="date"
                            headerCellRender={renderHeaderColumns}
                            editCellRender={renderUsingPeriodFrom}
                            allowEditing={true}
                        >
                        </Column>
                        <Column
                            caption={Config.lang("Thoi_han_su_dung_den")}
                            dataField="UsingPeriodTo"
                            alignment="right"
                            width={230}
                            dataType="date"
                            headerCellRender={renderHeaderColumns}
                            editCellRender={renderUsingPeriodTo}
                            allowEditing={true}
                        >
                        </Column>
                        <Column
                            caption={Config.lang("Ghi_chu")}
                            dataField="VoucherNotes"
                            width={420}
                            allowEditing={true}
                        />
                        {/* hidden column */}
                        <Column
                            dataField="ProTransID"
                            visible={false}
                        />
                        <Column
                            dataField="EmployeeID"
                            visible={false}
                        />
                        <Column
                            dataField="EquipmentGroupID"
                            visible={false}
                        />
                        <Column
                            dataField="EquipmentID"
                            visible={false}
                        />
                        <Column
                            dataField="SizeGroupID"
                            visible={false}
                        />
                        <Column
                            dataField="SizeID"
                            visible={false}
                        />
                        <Column
                            dataField="UnitID"
                            visible={false}
                        />
                        <Column
                            dataField="NormID"
                            visible={false}
                        />
                        {/* action column */}
                        <Column
                            width={100}
                            alignment={"center"}
                            fixed={true}
                            fixedPosition={"right"}
                            allowEditing={false}
                            cellRender={(e) => renderAction(e, 1)}
                            visible={!Config.isMobile}
                        />
                        <Column
                            width={120}
                            caption={Config.lang("Hanh_dong")}
                            alignment={"center"}
                            cellRender={(e) => renderAction(e, 1)}
                            visible={Config.isMobile}
                        />
                    </GridContainer>
                </Col>
                {/* dinh kem */}
                <Col xs={12}>
                    <Attachment
                        ref={ref => attRef.current = ref}
                        multiple={true}
                        maxFile={5}
                        data={dataOldAttachments}
                        onChange={onChangeAttachments}
                    />
                </Col>
            </Row>
        </>
    )
}
