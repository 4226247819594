import React, {useState, useEffect} from 'react'
import { Column } from "devextreme-react/data-grid";
import GridContainer from "../../../grid-container/grid-container";
import {    
    DatePicker,
    Typography,
    FormGroup,
    Row,
    Col,
    Dropdown,
} from "diginet-core-ui/components";
import Config from '../../../../config';
import _ from "lodash";
import { useDispatch } from "react-redux";
import * as generalActions from '../../../../redux/general/general_actions';
import * as W51F1001Actions from '../../../../redux/W5X/W51F1001/W51F1001_actions';
import * as W51F2201Actions from '../../../../redux/W5X/W51F2201/W51F2201_actions';

export default function W51F2202FormInfo(props) {
    const FormID = "W51F2201";
    const {data} = props;
    const {
        Employee,
        Equipment,
        ReasonID,
        WareHouseID,
        VoucherDate,
        NormID,
    } = data;

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [currentEmployee, setCurrentEmployee] = useState("");
    const [dataCboWarehouse, setDataCboWarehouse] = useState([]); // data dropdown kho
    const [dataCboReason, setDataCboReason] = useState([]); // data dropdown ly do
    const [dataCboSize, setDataCboSize] = useState([]); // data dropdown size luoi 2
    const [dataNormName, setDataNormName] = useState([]); // data

    const loadCboWarehouse = () => {
        const params = {
            FormID: FormID,
            Languague: "84",
        }
        setLoading(true);
        dispatch(generalActions.getCboWarehouse(params, (err, data) => {
            setLoading(false);
            if (err) {
                return Config.popup.show('ERROR', err);
            }
            else if (data) {
                setDataCboWarehouse(data);
            }
        }))
    };

    const getCboReason = () => {
        setLoading(true);
        dispatch(W51F1001Actions.getCbReason((err, data) => {
            setLoading(false);
            if (err) {
                return Config.popup.show('ERROR', err);
            }
            else if (data) {
                setDataCboReason(data);
            }
        }))
    };

    const getCboSize = () => {
        setLoading(true);
        dispatch(W51F1001Actions.getCbSize((err, data) => {
            setLoading(false);
            if (err) {
                return Config.popup.show('ERROR', err);
            }
            else if (data) {
                setDataCboSize(data);
            }
        }))
    };

    const loadNormName = () => {
        setLoading(true);
        dispatch(W51F2201Actions.loadNormName((err, data) => {
            setLoading(false);
            if (err) {
                return Config.popup.show('ERROR', err);
            }
            else if (data) {
                setDataNormName(data);
            }
        }))
    };

    useEffect(() => {
        const { Employee } = data;
        if (!_.isEmpty(Employee)) {
            setCurrentEmployee(data?.Employee[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Employee])

    useEffect(() => {
        loadCboWarehouse();
        getCboReason();
        getCboSize();
        loadNormName();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const onRowClick = (e) => {
        if (!e) return;
        setCurrentEmployee(e.data);
        }

    const renderSizeCell = (e) => {
        const { data } = e;
        return (
            <Dropdown
                valueExpr="SizeName"
                displayExpr="SizeName"
                dataSource={dataCboSize}
                value={data?.SizeName}
            />
        )
    };

    return (
        <>
            <FormGroup>
                <Row>
                    <Col xs={12} sm={12} md={6}>
                        <Dropdown
                            label={Config.lang("Kho")}
                            value={WareHouseID}
                            valueExpr="WareHouseID"
                            displayExpr="WareHouseName"
                            dataSource={dataCboWarehouse}
                            readOnly
                        />
                    </Col>
                    <Col xs={12} sm={12} md={6}>
                        <DatePicker
                            required
                            label={Config.lang("Ngay_de_xuat")}
                            value={VoucherDate}
                            displayFormat='DD/MM/YYYY'
                            placeholder='DD/MM/YYYY'
                            readOnly
                        />
                    </Col>
                    <Col xs={12} sm={12} md={12}>
                        <Dropdown
                            label={Config.lang("Ly_do")}
                            value={ReasonID}
                            valueExpr="Reason"
                            displayExpr="ReasonName"
                            dataSource={dataCboReason}
                            readOnly
                        />
                    </Col>
                    <Col xs={12} sm={12} md={12}>
                        <Dropdown
                            label={Config.lang("Dinh_muc_CCLD")}
                            value={_.isString(NormID) && !_.isEmpty(NormID) ? NormID.split(',') : NormID}
                            valueExpr="NormID"
                            displayExpr="{NormID} - {NormName}"
                            multiple
                            dataSource={dataNormName}
                            readOnly
                        />
                    </Col>
                </Row>
                {/* luoi 1 */}
                <FormGroup className="mgt6x">
                    <GridContainer
                        keyExpr="EmployeeID"
                        dataSource={Employee ?? []}
                        itemPerPage={10}
                        typePaging={"default"}
                        filterRow={{
                            visible: true,
                            showOperationChooser: false,
                        }}
                        onRowClick={onRowClick}
                        loading={loading}
                    >
                        <Column
                            caption={Config.lang("Ma_nhan_vien")}
                            dataField="EmployeeID"
                            width={120}
                            allowEditing={false}
                        />
                        <Column
                            caption={Config.lang("Ten_nhan_vien")}
                            dataField="EmployeeName"
                            width={300}
                            allowEditing={false}
                        />
                        <Column
                            caption={Config.lang("Size_ao")}
                            dataField={"ShirtSizeName"}
                            alignment="center"
                            width={120}
                            allowEditing={false}
                        />
                        <Column
                            caption={Config.lang("Size_quan")}
                            dataField="TrousersSizeName"
                            alignment="center"
                            width={120}
                            allowEditing={false}
                        />
                        <Column
                            caption={Config.lang("Size_giay")}
                            dataField="ShoeSizeName"
                            alignment="center"
                            width={120}
                            allowEditing={false}
                        />
                        <Column
                            caption={Config.lang("Size_do_sach")}
                            dataField="ClothesSizeName"
                            alignment="center"
                            width={120}
                            allowEditing={false}
                        />
                        {/* hidden column */}
                        <Column
                            dataField="ShirtSizeNameID"
                            visible={false}
                        />
                        <Column
                            dataField="TrousersSizeID"
                            visible={false}
                        />
                        <Column
                            dataField="ShoeSizeID"
                            visible={false}
                        />
                        <Column
                            dataField="ClothesSizeID"
                            visible={false}
                        />
                    </GridContainer>
                </FormGroup>
                <FormGroup className="mgt6x">
                    <Row>
                        <Col xs={12} sm={12} md={6}>
                            <Typography type="h3">
                                {`${Config.lang("Thong_tin_de_xuat_cap_phat_cho")}:`}
                            </Typography>
                        </Col>
                        <Col xs={12} sm={12} md={6}>
                            <Dropdown
                                readOnly
                                value={currentEmployee}
                                dataSource={Employee}
                                displayExpr="{EmployeeID} - {EmployeeName}"
                                iconExpr={{ key: 'UserPictureURL', prefix: Config.getCDNPath() }}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                {/* luoi 2 */}
                <FormGroup className="mgt4x">
                    <GridContainer
                        keyExpr="EquipmentID"
                        dataSource={Equipment?.filter(p => p?.EmployeeID === currentEmployee?.EmployeeID) ?? []}
                        itemPerPage={10}
                        typePaging={"default"}
                        filterRow={{
                            visible: true,
                            showOperationChooser: false,
                        }}
                        editing={{
                            mode: 'cell',
                            refreshMode: "reshape",
                            allowUpdating: true,
                        }}
                        sorting={{
                            mode: "none"
                        }}
                        onChangePage={page => { setTimeout(() => setCurrentPage(page), 300) }}
                        onEditorPreparing={e => {
                            if (
                                e.parentType === "filterRow" &&
                                e.dataField === "InterviewDateText"
                            ) {
                                e.editorOptions.onValueChanged = val => {
                                    e.setValue(val.value);
                                };
                            }
                        }}
                        loading={loading}
                    >
                        <Column
                            caption={Config.lang("STT")}
                            cellRender={e => currentPage * 10 + e.rowIndex + 1}
                            width={80}
                            allowEditing={false}
                        />
                        <Column
                            caption={Config.lang("Nhom_cong_cu_lao_dong")}
                            dataField="EquipmentGroupName"
                            width={180}
                            allowEditing={false}
                        />
                        <Column
                            caption={Config.lang("Cong_cu_lao_dong")}
                            dataField="EquipmentName"
                            width={240}
                            allowEditing={false}
                        />
                        <Column
                            caption={Config.lang("Size")}
                            dataField="SizeName"
                            cellRender={(e) => renderSizeCell(e)}
                            alignment={"center"}
                            width={120}
                            allowEditing={false}
                        />
                        <Column
                            caption={Config.lang("Don_vi_tinh")}
                            dataField="UnitName"
                            width={120}
                            allowEditing={false}
                        />
                        <Column
                            caption={Config.lang("So_luong")}
                            dataField="ProposalQTY"
                            alignment="right"
                            width={120}
                            allowEditing={true}
                            dataType="number"
                            format={{
                                type: "fixedPoint",
                                precision: 2,
                            }}
                        />
                        <Column
                            caption={Config.lang("So_thang_su_dung")}
                            dataField="UsingPeriod"
                            alignment="right"
                            width={160}
                            allowEditing={true}
                        />
                        <Column
                            caption={Config.lang("Thoi_han_su_dung_tu")}
                            dataField="UsingPeriodFrom"
                            alignment="right"
                            width={180}
                            dataType="date"
                            allowEditing={true}
                        />
                        <Column
                            caption={Config.lang("Thoi_han_su_dung_den")}
                            dataField="UsingPeriodTo"
                            alignment="right"
                            width={180}
                            dataType="date"
                            allowEditing={true}
                        />
                        <Column
                            caption={Config.lang("Ghi_chu")}
                            dataField="VoucherNotes"
                            width={300}
                            allowEditing={true}
                        />
                        {/* hidden column */}
                        <Column
                            dataField="ProTransID"
                            visible={false}
                        />
                        <Column
                            dataField="EmployeeID"
                            visible={false}
                        />
                        <Column
                            dataField="EquipmentGroupID"
                            visible={false}
                        />
                        <Column
                            dataField="EquipmentID"
                            visible={false}
                        />
                        <Column
                            dataField="SizeGroupID"
                            visible={false}
                        />
                        <Column
                            dataField="SizeID"
                            visible={false}
                        />
                        <Column
                            dataField="UnitID"
                            visible={false}
                        />
                        <Column
                            dataField="NormID"
                            visible={false}
                        />
                    </GridContainer>
                </FormGroup>

            </FormGroup>
        </>
    )
}
