/**
 * @copyright 2020 @ DigiNet
 * @author DINHTHIEN
 * @create 6/3/2020
 * @Example
 */

import React, { Component } from "react";
import { browserHistory } from "react-router";
import * as W51F1001Actions from "../../../../redux/W5X/W51F1001/W51F1001_actions";
import * as W51F2201Actions from "../../../../redux/W5X/W51F2201/W51F2201_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import W09F6000 from "../../../W0X/W09/W09F6000/W09F6000";
import Config from "../../../../config";
import GridContainer from "../../../grid-container/grid-container";
import { Column, RequiredRule, CustomRule, KeyboardNavigation } from "devextreme-react/data-grid";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import _ from "lodash";
import { MForm } from "../../../common/form-material";
import moment from "moment";
import UserImage from "../../../common/user/user-image";
import W51F1002 from "../W51F1002/W51F1002";
import CDN from "../../../CDN";
import GridActionBar from "../../../grid-container/grid-actionbar";
import {
    Dropdown,
    TextInput,
    Row,
    Col,
    Icon as Icons,
    Attachment,
    DatePicker,
    ButtonIcon,
    Typography,
    Tooltip,
    Button,
    Label,
} from "diginet-core-ui/components";
import { makeStyles } from "diginet-core-ui/theme";

const useStyle = makeStyles(() => ({
    dataGrid: {
        "& .dx-datagrid-rowsview": {
            "& tr.dx-row.dx-data-row": {
                "& .grid-action-hover": {
                    marginTop: "0.3%",
                },
                "& .dx-datagrid.dx-row > td": {
                    paddingTop: "0 !important",
                    paddingBottom: "0 !important",
                },
            },
            "& .dx-datagrid-validator.dx-validator.dx-visibility-change-handler.dx-editor-cell.dx-datagrid-invalid": {
                "& .dx-texteditor-container": {
                    "& .dx-texteditor-input-container": {
                        border: "none",
                        backgroundColor: "#EFBAB6",
                    },
                },
            },
        },
    },
}));

class W51F1001 extends Component {
    constructor(props) {
        super(props);
        this.FormID = "W51F1001";
        this.Language = Config.language || "84";
        this.state = {
            error: {},
            employeeSelected: {},
            employeeIDNotAllocated: [],
            masterData: {
                warehouseID: "",
                reason: "",
                examineDate: "",
                TransTypeID2: "",
                TransTypeName: "",
                VoucherTypeID2: "",
                VoucherTypeName: "",
                ProjectID: "",
                ProjectName: "",
                VoucherNo: "",
                IsVoucher: 0,
                ProposalID: "",
            },
            dataCbReason: [],
            dataCbEquipmentStatus: [
                {
                    EquipmentStatusID: "000",
                    EquipmentStatusName: Config.lang("Cu"),
                },
                {
                    EquipmentStatusID: "001",
                    EquipmentStatusName: Config.lang("Moi1"),
                },
            ],
            dataCbSize: [],
            dataCbProposal: [],
            isSaved: false,
            delRow: false,
            uploading: false,
            attachmentLoading: false,
            gridEmployees: [],
            gridEquipments: [],
            dataOldAttachments: [],
            gridEmpLoading: false,
            gridEquipLoading: false,
            showEmployeeSelect: false,
            disabled: false,
            disableSave: false,
            loadingSave: false,
            isMode: null,
            edit: true,
            rowData: null,
            showW51F1002Popup: false,
            isChanged: false,
        };
        this.attachments = [];
        this.deletedFile = [];
        this.setTimeoutId = "";
        this.newRowIndex = false;
        this.isSubmitting = false;
    }
    getWareHouse = () => {
        this.props.w51f1001Actions.getWareHouse(error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };
    loadCaptions = () => {
        const { FormID, Language } = this;
        const params = {
            FormID,
            Language,
        };
        this.setState({ gridEmpLoading: true, gridEquipLoading: true });
        this.props.w51f1001Actions.getCaption(params, (err, res) => {
            this.setState({ gridEmpLoading: false, gridEquipLoading: false });
            const captionGrid1 = _.get(res, "grid1", false);
            const captionGrid2 = _.get(res, "grid2", false);

            if (err) {
                Config.popup.show("ERROR", err);
                return;
            }
            if (!captionGrid1) return;
            this.setState({
                gridEmployees: this.state.gridEmployees.map(item => ({
                    ...item,
                    ...captionGrid1.reduce((obj, item) => {
                        obj[item.RefID] = 0;
                        return obj;
                    }, {}),
                })),
                gridEquipments: this.state.gridEquipments.map(item => ({
                    ...item,
                    ...captionGrid2.reduce((obj, item) => {
                        obj[item.RefID] = 0;
                        return obj;
                    }, {}),
                })),
            });
        });
    };

    loadCboWarehouse = () => {
        const { FormID, Language } = this;
        const params = {
            FormID,
            Language,
        };
        this.setState({ disabled: true });
        this.props.generalActions.getCboWarehouse(params, (err, data) => {
            this.setState({ disabled: false });
            if (err) Config.popup.show("ERROR", err);
            else if (data && data.length > 0) {
                if (this.getInfo()?.mode === "add") {
                    this.setState({
                        masterData: { ...this.state.masterData, warehouseID: data[0]?.WareHouseID || "" },
                    });
                }
            }
        });
    };

    loadMaster = () => {
        const { FormID, Language } = this;
        const { getCboWarehouse } = this.props;
        const { TransGroupID } = this.getInfo();
        const { mode } = this.getInfo();
        const params = {
            FormID,
            Language,
            TransGroupID,
        };
        this.setState({ gridEmpLoading: true, disabled: true });
        this.props.w51f1001Actions.getMaster(params, (err, res) => {
            this.setState({ gridEmpLoading: false, disabled: false });
            const rows = _.get(res, "rows", false);
            if (err) {
                Config.popup.show("INFO", err);
                return;
            }
            if (!rows) return;
            const gridEmployees = rows.map(item => ({
                ...item,
                EmployeeName: _.get(Config.getUser({ EmployeeID: item.EmployeeID }), "EmployeeName", ""),
                ProjectID: _.get(item, "ProjectID", ""),
                ProjectName: _.get(item, "ProjectName", ""),
            }));
            if (mode === "add") {
                let _res = { ...res };
                delete _res.rows;
                this.setState({
                    masterData: { ...this.state.masterData, ..._res },
                });
                return;
            }
            this.setState({
                masterData: {
                    warehouseID: _.get(res, "WareHouseID", getCboWarehouse[0].WareHouseID),
                    reason: _.get(res, "Reason", ""),
                    examineDate: _.get(res, "ExamineDate", ""),

                    TransTypeID2: _.get(res, "TransTypeID2", ""),
                    TransTypeName: _.get(res, "TransTypeName", ""),
                    VoucherTypeID2: _.get(res, "VoucherTypeID2", ""),
                    VoucherTypeName: _.get(res, "VoucherTypeName", ""),
                    ProjectID: _.get(res, "ProjectID", ""),
                    ProjectName: _.get(res, "ProjectName", ""),
                    VoucherNo: _.get(res, "VoucherNo", ""),
                    IsVoucher: _.get(res, "IsVoucher", ""),
                },
                gridEmployees,
                employeeSelected: { ...gridEmployees[0] },
            });
        });
    };

    loadCboReason = () => {
        this.props.w51f1001Actions.getCbReason((err, data) => {
            if (err) return Config.popup.show("ERROR", err);
            this.setState({ dataCbReason: data });
        });
    };

    loadCboSize = () => {
        this.props.w51f1001Actions.getCbSize((err, data) => {
            if (err) return Config.popup.show("ERROR", err);
            this.setState({ dataCbSize: data });
        });
    };

    loadCboProposal = () => {
        this.props.w51f1001Actions.getProposal({}, err => {
            if (err) Config.popup.show("ERROR", err);
        });
    };

    reloadDetail = async () => {
        const { FormID, Language } = this;
        const { gridEmployees, gridEquipments } = this.state;
        const arrEmpIDNotAllocated = gridEmployees.map(item => item.EmployeeID).filter(this.checkEmployeeAllocation);
        if (_.isEmpty(arrEmpIDNotAllocated)) {
            Config.popup.show("INFO", Config.lang("Tat_ca_nhan_vien_da_duoc_cap_phat_CCLD"));
            return;
        }
        const params = {
            FormID,
            Language,
            data: JSON.stringify(gridEmployees.map(item => item.EmployeeID)),
        };
        this.setState({ gridEmpLoading: true, gridEquipLoading: true, disabled: true });
        this.props.w51f1001Actions.copy(params, (err, dataCopy) => {
            this.setState({ gridEmpLoading: false, gridEquipLoading: false, disabled: false });
            if (err) {
                Config.popup.show("ERROR", err);
            } else {
                this.setState(
                    {
                        gridEquipments: _.uniqBy([...gridEquipments, ...dataCopy], v =>
                            [v.EmployeeID, v.EquipmentID].join()
                        ),
                    },
                    () => {
                        if (!this.state.isChanged) this.setState({ isChanged: true });
                        Config.notify.show("success", Config.lang("Da_sao_chep_cap_phat_truoc"), 2000);
                    }
                );
            }
        });
    };

    loadDetail = () => {
        const { FormID, Language } = this;
        const { TransGroupID } = this.getInfo();
        const params = {
            FormID,
            Language,
            TransGroupID,
        };
        this.setState({ gridEquipLoading: true });
        this.props.w51f1001Actions.getDetail(params, (err, data) => {
            this.setState({ gridEquipLoading: false });
            if (err) {
                Config.popup.show("INFO", _.get(err, "message", Config.lang("Loi_chua_xac_dinh")));
                return;
            }
            if (!data) return;
            let usingPeriodTo = moment(data[0].UsingPeriodFrom)
                .add(Number(data[0].UsingPeriod), "month")
                .subtract(Number(data[0].UsingPeriod) === 0 ? 0 : 1, "days")
                .format("YYYY-MM-DD");
            if (!moment(usingPeriodTo).isValid() || data[0].UsingPeriod === 0 || data[0].UsingPeriod === null) {
                data[0].UsingPeriodTo = null;
            } else {
                data[0].UsingPeriodTo = usingPeriodTo;
            }
            this.setState({
                gridEquipments: data,
            });
        });
    };

    loadSelectedEmployees = async () => {
        const { Language, FormID } = this;
        const { gridEmployees } = this.state;
        const { mode } = this.props;
        const params = {
            FormID,
            Language,
        };
        this.setState({ gridEmpLoading: true });
        this.props.w51f1001Actions.loadChoose(params, (err, dataLoadChoose) => {
            this.setState({ gridEmpLoading: false });
            if (err) Config.popup.show("ERROR", err);
            else {
                const employeeSelected = (dataLoadChoose || []).map(item => ({
                    ...item,
                    EmployeeName: _.get(Config.getUser({ EmployeeID: item.EmployeeID }), "EmployeeName", ""),
                }));
                if (!employeeSelected) return;
                const dataEmployees = _.uniqBy([...gridEmployees, ...employeeSelected], "EmployeeID");
                !_.isEqual(dataEmployees, gridEmployees) &&
                    this.setState({ gridEmployees: dataEmployees, employeeSelected: { ...dataEmployees[0] } }, () => {
                        if (!this.state.isChanged) this.setState({ isChanged: true });
                        if (mode === "edit") this.loadDetail();
                    });
            }
        });
    };

    _removeCDN = () => {
        if (this.deletedFile && this.deletedFile.length > 0) {
            this.deletedFile.forEach(e => {
                const path = e.URL.split("=");
                if (path && path.length > 1) {
                    const params = {
                        path: path[1],
                    };
                    CDN.removeFile(params);
                }
            });
            this.deletedFile = [];
        }
    };

    _getAttachments = file => {
        const dataFile = file?.data?.paths || [];
        const listAttachments = Config.helpers.getFileInfomations(dataFile);
        const { dataOldAttachments } = this.state;
        let arrAttachment = [];
        listAttachments.forEach(att => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileName ? att.fileName.split(".").pop() : "",
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    getInfo = flag => {
        const { location } = this.props;
        if (location && location.state) {
            return {
                mode: _.get(location, "state.mode", ""),
                TransGroupID: _.get(location, "state.TransGroupID", ""),
                isPermissionForm: _.get(location, "state.isPermissionForm", 0),
            };
        } else {
            if (flag) {
                browserHistory.push(Config.getRootPath() + "W51F1000");
                return null;
            }
            return false;
        }
    };

    loadAttachments = () => {
        const { TransGroupID } = this.getInfo();
        const param = {
            KeyID: TransGroupID,
        };
        this.props.generalActions.getAttachmentsByTransID(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data && data.length > 0) {
                this.setState({
                    dataOldAttachments: data,
                    attachmentLoading: false,
                });
            }
            this.setState({
                loading: false,
            });
        });
    };

    checkEquipmentInventory = async () => {
        const { gridEquipments, masterData } = this.state;
        const { FormID, Language } = this;
        const { mode, TransGroupID } = this.getInfo();
        const params = {
            Mode: mode === "edit" ? 1 : 0,
            FormID,
            Language,
            TransGroupID,
            DivisionID: Config.getDivisionID(),
            WareHouseID: masterData.warehouseID,
        };
        this.setState({ gridEmpLoading: true, gridEquipLoading: true });
        this.props.w51f1001Actions.count(params, (err, dataCount) => {
            this.setState({ gridEmpLoading: false, gridEquipLoading: false });
            if (err) Config.popup.show("ERROR", err);
            else {
                let objOldQuantity = {};
                const objEquipmentQuantity = gridEquipments.reduce((obj, item) => {
                    obj[item.EquipmentID] = item.Quantity + (objOldQuantity[item.EquipmentID] || 0);
                    objOldQuantity[item.EquipmentID] = item.Quantity;
                    return obj;
                }, {});
                return dataCount
                    .filter(item => _.includes(Object.keys(objEquipmentQuantity), item.EquipmentID))
                    .filter(item => item.Quantity - objEquipmentQuantity[item.EquipmentID] < 0)
                    .map(itemRes => ({
                        name: gridEquipments.filter(itemEquip => itemEquip.EquipmentID === itemRes.EquipmentID)[0]
                            .EquipmentName,
                        inventory: itemRes.Quantity,
                        allocated: objEquipmentQuantity[itemRes.EquipmentID],
                    }));
            }
        });
    };

    checkEmployeeAllocation = employeeId => {
        const { gridEquipments } = this.state;
        return !_.some(gridEquipments, { EmployeeID: employeeId });
    };

    onViewAllocations = () => {
        this.setState({
            isMode: 0,
            edit: false,
            rowData: null,
            showW51F1002Popup: true,
        });
    };

    closePopupAllocation = () => {
        this.setState({
            showW51F1002Popup: false,
        });
    };

    onChosenAllocation = data => {
        const { gridEmployees, gridEquipments, employeeSelected } = this.state;
        const arrEquipAllocated = gridEmployees
            .filter(item => item.EmployeeID === employeeSelected.EmployeeID)
            .reduce((arr, item) => {
                arr.push(
                    ...data.map(({ EquipmentName, EquipmentID, UnitName, UnitID, SizeGroupID }) => ({
                        EmployeeID: item.EmployeeID,
                        EquipmentName,
                        UsingPeriodFrom: moment().format("YYYY-MM-DD"),
                        UsingPeriodTo: "",
                        Quantity: 1,
                        EquipmentID,
                        SizeGroupID,
                        UnitName: UnitName || "",
                        UnitID,
                    }))
                );
                return arr;
            }, []);
        if (!_.isEqual(arrEquipAllocated, gridEquipments)) {
            this.setState(
                {
                    gridEquipments: _.uniqBy([...gridEquipments, ...arrEquipAllocated], v =>
                        [v.EmployeeID, v.EquipmentID].join()
                    ),
                    showW51F1002Popup: false,
                },
                () => {
                    if (!this.state.isChanged) this.setState({ isChanged: true });
                    Config.notify.show("success", Config.lang("CCLD_da_cap_phat"), 2000);
                }
            );
        }
    };

    onAddAllocations = () => {
        this.setState({
            isMode: 1,
            edit: true,
            rowData: null,
            showW51F1002Popup: true,
        });
    };

    openEmployeeSelect = () => {
        this.setState({ showEmployeeSelect: true });
        this.isRenderModalW09F6000 = true;
    };

    onCloseEmployeeSelect = () => {
        this.setState({ showEmployeeSelect: false });
    };

    onSelectedEmployee = () => {
        this.loadSelectedEmployees();
        this.setState({ showEmployeeSelect: false });
    };

    onChangeAttachments = e => {
        this.attachments = e?.allNewAttached || [];
        if (e?.removedAttached?.length > 0) {
            this.deletedFile = [...e.removedAttached];
            const _arrRemove = this.deletedFile.map(d => d.AttachmentID);
            this.setState(prevState => ({
                dataOldAttachments: prevState.dataOldAttachments.filter(att => {
                    return _arrRemove.indexOf(att.AttachmentID) < 0;
                }),
            }));
        }
    };

    onUploading = value => {
        this.setState({ uploading: value });
    };

    _uploadFile = (files, isAsync, cb) => {
        if (!files.length) return null;
        if (isAsync) {
            return CDN.uploadFileSync(files);
        } else {
            return CDN.uploadFile(files, null, cb);
        }
    };

    onSave = async () => {
        if (this.isSubmitting) return;
        this.isSubmitting = true;
        const {
            gridEmployees,
            gridEquipments,
            masterData: {
                warehouseID,
                reason,
                examineDate,
                TransTypeID2,
                VoucherTypeID2,
                ProjectID,
                VoucherNo,
                ProposalID,
            },
        } = this.state;
        const { FormID } = this;
        const { TransGroupID } = this.getInfo();
        if (!this.validationField()) {
            this.isSubmitting = false;
            return;
        }
        const arrEmpIDNotAllocated = gridEmployees.map(item => item.EmployeeID).filter(this.checkEmployeeAllocation);
        const arrEquipNotAllocated = await this.checkEquipmentInventory();
        // const arrAttachments = this._getAttachments();
        const paramData = gridEmployees.map(itemEmp => ({
            EmployeeID: itemEmp.EmployeeID,
            ..._.pickBy(itemEmp, (v, k) => _.startsWith(k, "RefNum")),
            detail: gridEquipments
                .filter(itemEquip => itemEquip.EmployeeID === itemEmp.EmployeeID)
                .map(itemDetail => ({
                    SignerID: "",
                    ReasonID: reason ?? "",
                    ProTransID: "",
                    TranTypeID: "",
                    UnitPrice: 0,
                    Amount: 0,
                    UsingPeriodFrom: itemDetail.UsingPeriodFrom,
                    ..._.omit(itemDetail, ["LastModifyDate", "CreateUserID", "CreateDate", "LastModifyUserID"]),
                    UsingPeriodTo: itemDetail.UsingPeriodTo
                        ? moment(itemDetail.UsingPeriodTo, "YYYY/MM/DD").format("YYYY-MM-DD")
                        : null,
                    UsingPeriod: itemDetail.UsingPeriod || 0,
                    ..._.mapValues(
                        _.pickBy(itemDetail, (v, k) => _.startsWith(k, "EquipRef")),
                        (v, k, o) => (o[k] = _.toString(v))
                    ),
                })),
        }));
        const dataResUploadFile = await this._uploadFile(this.attachments, true);
        const arrAttachments = this._getAttachments(dataResUploadFile);
        const params = {
            TransGroupID,
            FormID,
            DivisionID: Config.getDivisionID(),
            WareHouseID: warehouseID,
            Reason: reason,
            ExamineDate: examineDate,
            employee: JSON.stringify(paramData),
            arrAttachment: JSON.stringify(arrAttachments),
            TransTypeID2,
            VoucherTypeID2,
            ProjectID,
            VoucherNo,
            ProposalID,
        };
        if (!_.isEmpty(arrEquipNotAllocated)) {
            const message = arrEquipNotAllocated.map(
                item =>
                    `<br> ${item.name}: ${Config.lang("Ton")}: (${item.inventory}) - ${Config.lang("Cap_phat")}: (${
                        item.allocated
                    })`
            );
            const messageErr = Config.lang("So_luong_ton_kho_khong_du_cap_phat") + message;
            Config.popup.show("INFO", messageErr);
            this.isSubmitting = false;
            return;
        }
        this.isSubmitting = false;
        if (!_.isEmpty(arrEmpIDNotAllocated)) {
            this.setState({ employeeIDNotAllocated: arrEmpIDNotAllocated });
            Config.popup.show(
                "YES_NO",
                Config.lang("Ton_tai_nhan_vien_chua_duoc_cap_phat_CCLD_._Ban_co_muon_luu_khong?"),
                () => {
                    this.onSaveApi(params);
                }
            );
        } else {
            this.setState({ employeeIDNotAllocated: [] });
            this.onSaveApi(params);
        }
    };

    onSaveApi = async params => {
        if (this.isSubmitting) return;
        this.isSubmitting = true;
        const { mode, isPermissionForm } = this.getInfo();
        this.setState({
            gridEmpLoading: true,
            gridEquipLoading: true,
            attachmentLoading: true,
            disabled: true,
            loadingSave: true,
        });
        this.props.w51f1001Actions[mode](params, (err, data) => {
            this.setState({
                gridEmpLoading: false,
                gridEquipLoading: false,
                attachmentLoading: false,
                disabled: false,
                loadingSave: false,
            });
            this.isSubmitting = false;
            if (err || data?.Status === 1) {
                Config.popup.show("ERROR", err);
            } else if (data?.Status === 0) {
                Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
                this._removeCDN();
                this.setState(
                    { disableSave: true, isSaved: true, isChanged: false, employeeIDNotAllocated: [] },
                    () => {
                        if (mode !== "edit") {
                            browserHistory.push({
                                pathname: Config.getRootPath() + "W51F1001",
                                state: {
                                    mode: "edit",
                                    TransGroupID: data.TransGroupID,
                                    isPermissionForm: isPermissionForm,
                                },
                            });
                        }
                    }
                );
            }
        });
    };

    onBack = () => {
        const { isSaved } = this.state;
        let alert = !isSaved ? window.confirm(Config.lang("Cac_thay_doi_da_thuc_hien_se_khong_duoc_luu.")) : false;
        if (alert || isSaved) {
            browserHistory.push(Config.getRootPath() + "W51F1000");
        }
    };

    onSelectionChangedGridEmployee = e => {
        const isEditing = _.isEmpty(_.get(e, "currentDeselectedRowKeys", []));
        const selectedRow = e.selectedRowsData[0];
        if (e.component.hasEditData()) e.component.saveEditData();
        if (!isEditing || this.state.delRow) {
            this.setState({ employeeSelected: selectedRow });
        }
    };

    handleUpdateUsingPeriodTo = e => {
        const newData = _.get(e, "newData", {});
        const columnValid = ["UsingPeriod", "UsingPeriodFrom", "UsingPeriodTo"];
        const keyUpdate = Object.keys(newData);

        if (!_.includes(columnValid, ...keyUpdate)) return;
        const newUsingPeriod = _.get(newData, "UsingPeriod", _.get(e, "oldData.UsingPeriod", ""));
        const newUsingPeriodFrom = _.get(newData, "UsingPeriodFrom", _.get(e, "oldData.UsingPeriodFrom", null));
        let usingPeriodTo = moment(newUsingPeriodFrom)
            .add(Number(newUsingPeriod), "month")
            .subtract(Number(newUsingPeriod) === 0 ? 0 : 1, "days")
            .format("YYYY-MM-DD");
        if (!moment(usingPeriodTo).isValid() || newUsingPeriod === 0 || newUsingPeriod === null) usingPeriodTo = null;
        newUsingPeriod > 0 ? (e.oldData.UsingPeriodTo = usingPeriodTo) : (e.oldData.UsingPeriodTo = null);
    };

    onDeleteRow = (e, isGridEquipment) => {
        const { data } = e;
        const { gridEquipments } = this.state;
        const employeeID = _.get(data, "EmployeeID", "");
        const equipmentID = _.get(data, "EquipmentID", "");
        const typeGrid = !isGridEquipment ? Config.lang("Nhan_vien") : Config.lang("CCLD");
        if (!isGridEquipment && !this.checkEmployeeAllocation(employeeID)) {
            Config.popup.show("INFO", Config.lang("Ton_tai_nhan_vien_duoc_cap_phat_CCLD_,_Khong_duoc_phep_xoa"));
            return;
        }
        Config.popup.show("YES_NO", `${Config.lang("Ban_co_chac_muon_xoa?")} ${typeGrid}`, () => {
            if (!isGridEquipment) {
                e.component.deleteRow(e.rowIndex);

                this.setState(
                    {
                        delRow: true,
                    },
                    () => {
                        if (!this.state.isChanged) this.setState({ isChanged: true });
                    }
                );
            } else {
                this.setState(
                    {
                        gridEquipments: _.remove(gridEquipments, item =>
                            item.EquipmentID === equipmentID ? item.EmployeeID !== employeeID : true
                        ),
                    },
                    () => {
                        if (!this.state.isChanged) this.setState({ isChanged: true });
                    }
                );
            }
        });
    };

    onCopyRow = e => {
        const { gridEquipments, gridEmployees } = this.state;
        const rowCopied = _.get(e, "row.data", false);
        if (!rowCopied) return;
        Config.popup.show(
            "YES_NO",
            `${Config.lang("Sao_chep_CCLD")} ${rowCopied.EquipmentName} ${Config.lang("Cho_tat_ca_nhan_vien")}`,
            () => {
                const cpEquipment = gridEmployees
                    .filter(emp => emp.EmployeeID !== rowCopied.EmployeeID)
                    .reduce((arr, item) => {
                        arr.push({ ...rowCopied, EmployeeID: item.EmployeeID });
                        return arr;
                    }, []);
                if (_.isEmpty(cpEquipment)) return;
                this.setState(
                    {
                        gridEquipments: _.uniqBy([...gridEquipments, ...cpEquipment], v =>
                            [v.EmployeeID, v.EquipmentID].join()
                        ),
                    },
                    () => {
                        if (!this.state.isChanged) this.setState({ isChanged: true });
                        Config.notify.show(
                            "success",
                            `${Config.lang("Sao_chep_thanh_cong")}: ${rowCopied.EquipmentName}`,
                            3000
                        );
                    }
                );
            }
        );
    };

    onChangeMasterField = (key, data) => {
        const { error } = this.state;
        if (!this.state.isChanged) this.setState({ isChanged: true });
        const value = _.get(data, "value", _.get(data, "target.value", ""));
        if (error?.[key] && value) {
            this.setState({ error: { ...error, [key]: "" } });
        }
        this.setState({ masterData: { ...this.state.masterData, [key]: value } }, () => {
            if (key === "ProposalID") {
                this.loadForm(0);
            }
        });
    };

    loadForm = mode => {
        const { masterData } = this.state;
        const params = {
            Mode: mode,
            FormID: "W51F1001",
            TransGroupID: masterData.ProposalID,
        };
        this.props.W51F2201Actions.loadForm(params, (err, data) => {
            if (err) {
                return Config.popup.show("ERROR", err);
            } else if (data) {
                const Master = data.Master || {};
                const Employee = data.Employee || [];
                const Equipment = data.Equipment || [];
                this.setState({
                    masterData: {
                        ...masterData,
                        warehouseID: Master.WareHouseID,
                        reason: Master.ReasonID,
                    },
                    gridEmployees: Employee,
                    gridEquipments: Equipment,
                    employeeSelected: { ...Employee[0] },
                });
            }
        });
    };

    renderCaption = grid => {
        const { getCaption } = this.props;
        const captionGrid = _.get(getCaption, grid, false);
        if (!captionGrid) return;
        return captionGrid
            .filter(item => !item.Disabled)
            .map((item, ind) => {
                let format = {};
                if (item.IsMoney) format = { format: { type: "fixedPoint" } };
                return (
                    <Column
                        width={220}
                        allowEditing={true}
                        key={ind}
                        caption={item.RefCaption}
                        dataField={item.RefID}
                        alignment={"center"}
                        {...format}
                    />
                );
            });
    };

    renderEmpProfile = e => {
        const { data } = e.row;
        const { employeeIDNotAllocated } = this.state;
        const employeeId = _.get(data, "EmployeeID", "");
        let renderIconWarning = "";
        if (_.includes(employeeIDNotAllocated, _.get(data, "EmployeeID", ""))) {
            renderIconWarning = (
                <Tooltip title={Config.lang("Nhan_vien_chua_duoc_cap_phat_CCLD")} arrow={"true"}>
                    <Icons name={"Warning"} className={"mgr1x"} />
                </Tooltip>
            );
        }
        return (
            <div className={"display_row"}>
                {renderIconWarning}
                <div className={"display_row align-center"}>
                    <UserImage width={28} height={30} data={data} allowHover={false} />
                    {employeeId}
                </div>
            </div>
        );
    };

    renderButtonAction = (e, isGridEquipment = "") => {
        return (
            <GridActionBar className={"display_row align-center"}>
                {isGridEquipment && (
                    <ButtonIcon
                        name={"CopyFile"}
                        viewType={"text"}
                        circular
                        disabled={!e.data.Quantity > 0}
                        className={"mgr1x"}
                        onClick={() => this.onCopyRow(e)}
                    />
                )}
                <ButtonIcon
                    name="delete"
                    viewType={"text"}
                    circular
                    onClick={() => this.onDeleteRow(e, isGridEquipment)}
                />
            </GridActionBar>
        );
    };

    validationField = () => {
        const { masterData } = this.state;
        let validateRules = [
            {
                name: "ExamineDate",
                rules: "isRequired",
                value: masterData.examineDate,
            },
        ];
        if (this.props.wareHouseData.NumValue === 1) {
            validateRules = [
                ...validateRules,
                {
                    name: "WareHouseID",
                    rules: "isRequired",
                    value: masterData.warehouseID,
                },
            ];
        }
        const validateForm = MForm.formValidation(validateRules);
        if (Object.keys(validateForm).length !== 0) {
            this.setStateErrorText(validateForm);
            return false;
        } else {
            return true;
        }
    };

    setStateErrorText = value => {
        const { error } = this.state;
        this.setState({
            error: {
                ...error,
                ...value,
            },
        });
        return Object.keys(value).length !== 0;
    };

    renderEditCellEquipmentStatus = ({ data }) => {
        const { dataCbEquipmentStatus } = this.state;
        const { component, setValue, value } = data;
        return (
            <Dropdown
                dataSource={dataCbEquipmentStatus}
                displayExpr={"EquipmentStatusName"}
                valueExpr={"EquipmentStatusID"}
                value={value || ""}
                onChange={e => {
                    setValue(_.get(e, "value", ""));
                    component.closeEditCell();
                }}
            />
        );
    };

    renderCellEquipmentStatus = ({ data }) => {
        const { dataCbEquipmentStatus } = this.state;
        return _(dataCbEquipmentStatus)
            .filter(["EquipmentStatusID", data.EquipmentStatusID])
            .get("[0].EquipmentStatusName", "");
    };

    renderEditCellSize = ({ data }) => {
        const { dataCbSize } = this.state;
        const { component, setValue, rowIndex, value } = data;
        const groupedDataCbSize = _.filter(dataCbSize, item => item.SizeGroupID === data.data.SizeGroupID);
        return (
            <Dropdown
                dataSource={groupedDataCbSize}
                displayExpr={"SizeName"}
                valueExpr={"SizeID"}
                value={value || ""}
                onChange={e => {
                    const valueChange = _.get(e, "value", "");
                    const getSizeGroupID = _.get(groupedDataCbSize, "[0].SizeGroupID", "");
                    setValue(valueChange);
                    component.cellValue(rowIndex, "SizeGroupID", getSizeGroupID);
                    component.closeEditCell();
                }}
            />
        );
    };

    renderCellSize = ({ data }) => {
        const { dataCbSize } = this.state;
        return _(dataCbSize).filter(["SizeID", data?.SizeID]).get("[0].SizeName", "");
    };
    getInputValue = (id, name) => {
        return id && name ? `${id} - ${name}` : `${id ?? ""}${name ?? ""}`;
    };

    setProjectIDFromGrid1 = dt => {
        const newProjectID = dt?.[0]?.data?.ProjectID || "";
        if (newProjectID !== this.state.masterData?.ProjectID) {
            this.setState({
                masterData: {
                    ...this.state.masterData,
                    ProjectID: newProjectID,
                    ProjectName: dt?.[0]?.data?.ProjectName || "",
                },
            });
        }
    };

    render() {
        if (!this.getInfo(true)) return null;
        const { isPermissionForm, mode } = this.getInfo();
        const {
            disabled,
            gridEmpLoading,
            gridEquipLoading,
            showEmployeeSelect,
            gridEmployees,
            gridEquipments,
            employeeSelected,
            masterData,
            dataOldAttachments,
            dataCbReason,
            showW51F1002Popup,
            error,
            loadingSave,
            isChanged,
        } = this.state;
        const { getCboWarehouse, wareHouseData, getProposal } = this.props;
        const classes = useStyle();

        const warehouseNameSelected = _.get(
            getCboWarehouse.filter(item => item.WareHouseID === masterData.warehouseID),
            "[0]WareHouseName",
            ""
        );
        const mapSizeGroupID = new Map([
            ["AO", "ShirtSize"],
            ["QUAN", "TrousersSize"],
            ["GIAY", "ShoesSize"],
            ["DOSACH", "ClothesSize"],
        ]);

        const gridEquipEmployee = employeeSelected
            ? gridEquipments.filter(item => item.EmployeeID === employeeSelected.EmployeeID)
            : [];
        const rowEmployeesChoosen = gridEmployees.find(i => i.EmployeeID === employeeSelected?.EmployeeID);
        if (employeeSelected && rowEmployeesChoosen && gridEquipments?.length) {
            gridEquipments.forEach(i => {
                if (!i.SizeID && i.SizeID !== 0) i.SizeID = rowEmployeesChoosen[mapSizeGroupID.get(i.SizeGroupID)];
            });
        }
        let newDataCbReason = [...dataCbReason];
        const ixDataMasterReason = _.findIndex(dataCbReason, ["Reason", masterData.reason]);
        if (ixDataMasterReason < 0)
            newDataCbReason = [...newDataCbReason, { Reason: masterData.reason, ReasonName: masterData.reason }];
        return (
            <>
                <ActionToolbar title={Config.lang("Cap_phat_trang_thiet_bi_lao_dong")} onBack={this.onBack}>
                    <div className={"full_w display_row align-between"}>
                        <Button
                            label={Config.lang("Chon_nhan_vien")}
                            color={"primary"}
                            startIcon={"AddPerson"}
                            className={"mgr5"}
                            viewType={"filled"}
                            disabled={disabled || masterData.ProposalID}
                            onClick={this.openEmployeeSelect}
                        />
                        <Button
                            label={Config.lang("Luu")}
                            startIcon={"Save"}
                            color={"info"}
                            viewType={"filled"}
                            disabled={!gridEmployees.length > 0 || disabled || isPermissionForm < 2 || !isChanged}
                            loading={loadingSave}
                            onClick={this.onSave}
                        />
                    </div>
                </ActionToolbar>
                {(showEmployeeSelect || this.isRenderModalW09F6000) && (
                    <W09F6000
                        open={showEmployeeSelect}
                        FormID={"W51F1001"}
                        onChosen={this.onSelectedEmployee}
                        onClose={this.onCloseEmployeeSelect}
                    />
                )}
                {showW51F1002Popup && (
                    <W51F1002
                        open={showW51F1002Popup}
                        onClose={this.closePopupAllocation}
                        onChosen={this.onChosenAllocation}
                        isMode={this.state.isMode}
                        FormID={"W51F1002"}
                        WareHouseIDDef={masterData.warehouseID}
                    />
                )}
                <Row className={"align-right mgt4x"}>
                    <Col xl={6} lg={6} sm={12} xs={12}>
                        <Dropdown
                            label={Config.lang("Cap_phat_tu_de_xuat")}
                            value={masterData.ProposalID || ""}
                            valueExpr={"ProposalID"}
                            displayExpr={"ProposalName"}
                            multilineSelectedItem
                            dataSource={getProposal}
                            onChange={e => this.onChangeMasterField("ProposalID", e)}
                            disabled={disabled || masterData.IsUseProposal === 0}
                        />
                    </Col>
                    <Col xl={6} lg={6} sx={{display: {xs:"none", lg:"block"}}} />
                    <Col xl={3} lg={3} sm={6} xs={12}>
                        <Tooltip title={warehouseNameSelected} direction={"down"}>
                            <Dropdown
                                readOnly={mode === "edit" || wareHouseData.NumValue === 0}
                                dataSource={getCboWarehouse}
                                label={
                                    <div className={"display_row align-center"}>
                                        <ButtonIcon
                                            className={"mgr1x"}
                                            name={"Home"}
                                            viewType={"ghost"}
                                            circular
                                            size={"tiny"}
                                            onClick={this.onViewAllocations}
                                        />
                                        <Label required={wareHouseData.NumValue === 1}>{Config.lang("Kho")}</Label>
                                    </div>
                                }
                                displayExpr={"WareHouseName"}
                                valueExpr={"WareHouseID"}
                                disabled={disabled}
                                value={masterData.warehouseID}
                                error={error?.["WareHouseID"]}
                                onChange={e => this.onChangeMasterField("warehouseID", e)}
                            />
                        </Tooltip>
                    </Col>
                    <Col xl={3} lg={3} sm={6} xs={12}>
                        <DatePicker
                            label={Config.lang("Ngay_thuc_hien")}
                            value={masterData.examineDate || ""}
                            onChange={e => this.onChangeMasterField("examineDate", e)}
                            error={error?.["ExamineDate"]}
                            disabled={disabled}
                            required={true}
                            clearAble
                        />
                    </Col>
                    <Col xl={3} lg={3} sm={6} xs={12}>
                        <Dropdown
                            dataSource={newDataCbReason}
                            label={Config.lang("Ly_do_cap_phat")}
                            displayExpr={"ReasonName"}
                            valueExpr={"Reason"}
                            disabled={disabled}
                            value={masterData.reason || ""}
                            onChange={e => this.onChangeMasterField("reason", e)}
                        />
                    </Col>
                    <Col xl={3} lg={3} sm={6} xs={12}>
                        <Button
                            label={Config.lang("Sao_chep_cap_phat_truoc")}
                            color={"primary"}
                            startIcon={"CopyFile"}
                            className={"mgr5"}
                            disabled={disabled || !gridEmployees.length > 0 || masterData.ProposalID}
                            onClick={() => this.reloadDetail()}
                        />
                    </Col>

                    <Col xl={3} lg={3} sm={6} xs={12}>
                        <TextInput
                            label={Config.lang("Loai_nghiep_vu")}
                            value={this.getInputValue(masterData?.TransTypeID2, masterData?.TransTypeName)}
                            readOnly
                        />
                    </Col>
                    <Col xl={3} lg={3} sm={6} xs={12}>
                        <TextInput
                            label={Config.lang("Loai_phieu")}
                            value={this.getInputValue(masterData?.VoucherTypeID2, masterData?.VoucherTypeName)}
                            readOnly
                        />
                    </Col>
                    <Col xl={3} lg={3} sm={6} xs={12}>
                        <TextInput label={Config.lang("So_phieu")} value={masterData?.VoucherNo} readOnly />
                    </Col>
                    <Col xl={3} lg={3} sm={6} xs={12}>
                        <TextInput
                            label={Config.lang("Doi_tuong")}
                            value={this.getInputValue(masterData?.ProjectID, masterData?.ProjectName)}
                            readOnly
                        />
                    </Col>
                    {/* datagrid 1*/}
                    <Col xs={12}>
                        <GridContainer
                            reference={ref => (this.dataGrid1 = ref)}
                            gridProps={{
                                className: classes.dataGrid,
                            }}
                            editing={{
                                refreshMode: "reshape",
                                texts: {
                                    confirmDeleteMessage: "",
                                },
                                mode: "cell",
                                allowUpdating: true,
                                selectTextOnEditStart: true,
                                startEditAction: Config.isMobile ? "click" : "dblClick",
                            }}
                            selection={{ mode: "single" }}
                            onSelectionChanged={this.onSelectionChangedGridEmployee}
                            keyExpr={"EmployeeID"}
                            loading={gridEmpLoading}
                            hoverStateEnabled={true}
                            style={{ border: "none" }}
                            dataSource={gridEmployees}
                            showRowLines={false}
                            showBorders={false}
                            showColumnLines={false}
                            noDataText={Config.lang("No_data")}
                            onContentReady={e => {
                                let grid = e.component;
                                let selection = grid.getSelectedRowKeys();
                                if (selection.length === 0) {
                                    grid.selectRowsByIndexes([0]);
                                }

                                /**
                                 * Đối với Mode Thêm mới: ProjectID, ProjectName map với ProjectID, ProjectName dòng ở đầu tiên
                                 * Đối với Mode Sửa, Xem: Nếu IsVoucher = 1: ProjectID, ProjectName map với ProjectID, ProjectName dòng đầu tiên ở bảng detail Grid 1. Ngược lại, trả rỗng
                                 */
                                if (masterData.IsVoucher === 1) {
                                    const dataSource = e.component.getVisibleRows();
                                    this.setProjectIDFromGrid1(dataSource);
                                }
                            }}
                            onRowUpdated={() => {
                                if (!this.state.isChanged) this.setState({ isChanged: true });
                            }}
                        >
                            <KeyboardNavigation enterKeyAction={"startEdit"} enterKeyDirection={"column"} />
                            <Column
                                caption={Config.lang("Hanh_dong")}
                                alignment={"center"}
                                visible={Config.isMobile}
                                allowEditing={false}
                                cellRender={this.renderButtonAction}
                            />
                            <Column
                                width={220}
                                caption={Config.lang("Ma_nhan_vien")}
                                allowEditing={false}
                                cellRender={this.renderEmpProfile}
                                alignment={"left"}
                            />
                            <Column
                                width={220}
                                allowEditing={false}
                                caption={Config.lang("Ten_nhan_vien")}
                                dataField={"EmployeeName"}
                                alignment={"left"}
                            />
                            <Column
                                allowEditing={false}
                                caption={Config.lang("Size_ao")}
                                dataField={"ShirtSize"}
                                alignment={"center"}
                            />
                            <Column
                                allowEditing={false}
                                caption={Config.lang("Size_quan")}
                                dataField={"TrousersSize"}
                                alignment={"center"}
                            />
                            <Column
                                allowEditing={false}
                                caption={Config.lang("Size_giay")}
                                dataField={"ShoesSize"}
                                alignment={"center"}
                            />
                            <Column
                                allowEditing={false}
                                caption={Config.lang("Size_do_sach")}
                                dataField={"ClothesSize"}
                                alignment={"center"}
                            />
                            {this.renderCaption("grid1")}
                            <Column
                                alignment={"center"}
                                fixed={true}
                                fixedPosition={"right"}
                                allowEditing={false}
                                visible={!Config.isMobile}
                                cellRender={this.renderButtonAction}
                            />
                        </GridContainer>
                    </Col>

                    <Col xs={12} className={"display_row gap4x align-center flex-wrap"}>
                        <Typography type={"p3"} style={{ minWidth: Config.isMobile ? "100%" : 120 }}>
                            {Config.lang("Thong_tin_cap_phat_cho")}
                        </Typography>
                        <TextInput
                            value={
                                !_.isEmpty(employeeSelected)
                                    ? `${employeeSelected.EmployeeID} ${
                                          employeeSelected.EmployeeName ? "- " + employeeSelected.EmployeeName : ""
                                      }`
                                    : ""
                            }
                            style={{ maxWidth: 310 }}
                            readOnly={true}
                            startIcon={<UserImage width={24} height={24} data={employeeSelected} />}
                        />
                        <Button
                            label={Config.lang("Chon_CCLD")}
                            startIcon={"Input"}
                            color={"primary"}
                            disabled={
                                disabled || !_.get(employeeSelected, "EmployeeID", false) || masterData.ProposalID
                            }
                            onClick={this.onAddAllocations}
                        />
                    </Col>

                    <Col xs={12}>
                        {/*grid 2*/}
                        <GridContainer
                            gridProps={{
                                className: classes.dataGrid,
                            }}
                            editing={{
                                refreshMode: "reshape",
                                texts: {
                                    confirmDeleteMessage: "",
                                },
                                mode: "cell",
                                allowUpdating: true,
                                selectTextOnEditStart: true,
                                startEditAction: Config.isMobile ? "click" : "dblClick",
                            }}
                            selection={{ mode: "none" }}
                            onRowUpdating={this.handleUpdateUsingPeriodTo}
                            onFocusedCellChanging={e => {
                                this.newRowIndex = e.newRowIndex;
                            }}
                            hoverStateEnabled={true}
                            loading={gridEquipLoading}
                            style={{ border: "none" }}
                            dataSource={gridEquipEmployee}
                            showRowLines={false}
                            showBorders={false}
                            typeShort={true}
                            keyExpr={"EquipmentID"}
                            showColumnLines={false}
                            noDataText={Config.lang("No_data")}
                            onRowUpdated={() => {
                                if (!this.state.isChanged) this.setState({ isChanged: true });
                            }}
                        >
                            <KeyboardNavigation enterKeyAction={"startEdit"} enterKeyDirection={"column"} />
                            <Column
                                width={120}
                                caption={Config.lang("Hanh_dong")}
                                alignment={"center"}
                                allowEditing={false}
                                visible={Config.isMobile}
                                cellRender={e => this.renderButtonAction(e, true)}
                            />
                            <Column
                                allowEditing={false}
                                caption={Config.lang("STT")}
                                alignment={"center"}
                                cellRender={e => e.rowIndex + 1}
                                width={100}
                            />
                            <Column
                                allowEditing={false}
                                caption={Config.lang("Cong_cu_lao_dong")}
                                width={230}
                                cellRender={e => (
                                    <div
                                        style={{ display: "flex", height: 30, alignItems: "center" }}
                                    >{`${e.data.EquipmentName} - ${e.data.EquipmentID}`}</div>
                                )}
                                cssClass={"text-left"}
                            />
                            <Column
                                caption={Config.lang("Trang_thai_cong_cu_lao_dong")}
                                dataField={"EquipmentStatusID"}
                                editCellComponent={this.renderEditCellEquipmentStatus}
                                cellRender={this.renderCellEquipmentStatus}
                                width={250}
                            />
                            <Column
                                allowEditing={false}
                                caption={Config.lang("Don_vi_tinh")}
                                alignment={"center"}
                                width={200}
                                dataField={"UnitName"}
                            />
                            <Column
                                caption={Config.lang("Size")}
                                alignment={"center"}
                                dataField={"SizeID"}
                                width={200}
                                editCellComponent={this.renderEditCellSize}
                                cellRender={this.renderCellSize}
                            />
                            <Column dataField={"SizeGroupID"} visible={false} width={180} />
                            <Column
                                dataType="number"
                                caption={Config.lang("So_luong")}
                                alignment={"center"}
                                dataField={"Quantity"}
                                width={180}
                                allowEditing={!masterData.ProposalID}
                            >
                                <RequiredRule />
                                <CustomRule
                                    message={Config.lang("So_luong_phai_lon_hon_0")}
                                    validationCallback={e => e.value > 0}
                                />
                            </Column>
                            <Column
                                caption={Config.lang("So_thang_su_dung")}
                                width={180}
                                dataField={"UsingPeriod"}
                                dataType={"number"}
                                alignment={"center"}
                                calculateCellValue={e => e?.UsingPeriod ?? 0}
                                allowEditing={!masterData.ProposalID}
                                editorOptions={{
                                    min: 0,
                                    onInput: e => {
                                        let value = e.event.target?.value || "";
                                        if (value.includes("-")) value.replace("-", "").replace("e", "");
                                    },
                                }}
                            />
                            <Column
                                caption={Config.lang("Ngay_cap")}
                                alignment={"center"}
                                dataField={"UsingPeriodFrom"}
                                width={230}
                                dataType="date"
                            />
                            <Column
                                allowEditing={false}
                                width={230}
                                caption={Config.lang("Ngay_het_han")}
                                dataField={"UsingPeriodTo"}
                                alignment={"center"}
                                dataType="date"
                            />
                            {this.renderCaption("grid2")}
                            <Column
                                width={420}
                                caption={Config.lang("Ghi_chu")}
                                dataField={"NoteU"}
                                alignment={"left"}
                            />
                            <Column
                                width={100}
                                alignment={"center"}
                                fixed={true}
                                fixedPosition={"right"}
                                allowEditing={false}
                                visible={!Config.isMobile}
                                cellRender={e => this.renderButtonAction(e, true)}
                            />
                        </GridContainer>
                    </Col>
                    <Col md={12}>
                        <Attachment
                            isStripDomain={true}
                            style={{ minHeight: 120 }}
                            data={dataOldAttachments}
                            maxFile={5}
                            domain={Config.getCDNPath()}
                            onChange={this.onChangeAttachments}
                        />
                    </Col>
                </Row>
            </>
        );
    }

    componentDidMount = async () => {
        if (!this.getInfo()) return;
        const { mode } = this.getInfo();
        this.loadCaptions();
        this.getWareHouse();
        this.loadCboWarehouse();
        this.loadCboReason();
        this.loadCboSize();
        this.loadCboProposal();

        this.loadMaster();
        if (mode === "edit") {
            this.loadDetail();
            this.loadAttachments();
        }
        window.addEventListener("beforeunload", this.beforeUnload);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps?.location?.state?.mode !== this.props?.location?.state?.mode) {
            this.loadDetail();
            this.loadMaster();
            this.loadAttachments();
        }
    }

    beforeUnload = e => {
        e.preventDefault();
        e.returnValue = "";
        return;
    };

    componentWillUnmount = () => {
        window.removeEventListener("beforeunload", this.beforeUnload);
    };
}

export default compose(
    connect(
        state => ({
            getCaption: state.W51F1001.getCaption,
            getCboWarehouse: state.general.getCboWarehouse,
            wareHouseData: state.W51F1001.wareHouseData,
            getProposal: state.W51F1001.getProposal,
        }),
        dispatch => ({
            w51f1001Actions: bindActionCreators(W51F1001Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch),
            W51F2201Actions: bindActionCreators(W51F2201Actions, dispatch),
        })
    )
)(W51F1001);
